import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import https from '../../utils/axiosApi';
import defaultPlayerImage from "../../assets/images/cricket-player.png"
import Loader from '../../components/Common/Loader';
const MyContests = () => {
    const [activeTab, setActiveTab] = useState('All');
    const [allContestentData, setAllContestentData] = useState([])
    const [userContestentData, setUserContestentData] = useState([])

    const navigate = useNavigate()
    const location = useLocation();
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    const [isContestDataLoading, setIsContestDataLoading] = useState(false)




    const fetchAllContestentData = async () => {
        setIsContestDataLoading(true)
        let contestID = location.state.matchId

        https.get(`/ContestApi/GetContestStandings?contestId=${contestID}`).then(async (res) => {
            let resData = await res?.data?.data
            setAllContestentData(resData)
            setIsContestDataLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsContestDataLoading(true)
        })
    }




    const fetchUserContestentData = async () => {
        let contestID = location.state.matchId

        https.get(`/ContestApi/GetContestStandingsByContestForUser?contestId=${contestID}`).then(async (res) => {
            let resData = await res?.data?.data
            setUserContestentData(resData)
        }).catch((err) => console.log(err))
    }
    useEffect(() => {
        if (activeTab === 'All') {
            fetchAllContestentData();
        } else if (activeTab === 'Mine') {
            fetchUserContestentData();
        }
    }, [activeTab]);

    return (
        <div className='container' id='my-contest-area'>
            <div className="tab-menu">
                <button onClick={() => handleTabChange('All')} className={activeTab === 'All' ? 'active-btn' : ''}>
                    All
                </button>
                <button onClick={() => handleTabChange('Mine')} className={activeTab === 'Mine' ? 'active-btn' : ''}>
                    Mine
                </button>

            </div>
            <div className="tab-content">
                {activeTab === 'All' &&
                    <>

                        <table className="table table-bordered">
                            <thead style={{ backgroundColor: "transparent" }}>
                                <tr>
                                    <th ></th>
                                    <th></th>
                                    <th colSpan={2}><h4 className='fw-bold'>Position</h4></th>
                                    <th><h4 className='fw-bold'>
                                    Points<i class="fa-solid fa-rotate text-white mt-4 mx-2" title='Refresh' style={{ cursor: "pointer" }} onClick={fetchAllContestentData}></i>   </h4></th>
                                </tr>
                            </thead>

                            <tbody id='table-body' >
                                {
                                    isContestDataLoading ? <Loader /> : allContestentData?.sort((b, a) => b?.position - a?.position)?.map((item) => {
                                        return (
                                            <tr className='select-team-caption-voice-caption' onClick={() => {
                                                navigate(`/view-team`, { state: { teamId: item?.userTeamId, teamName: item?.teamName } });
                                            }}>
                                                <td></td>
                                                <td width={"2%"}>{item?.position}</td>
                                                <td><img src={item?.userProfilePic ? item?.userProfilePic : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100 }} /></td>
                                                <td scope="col"><div className='d-flex flex-column '><span className='fw-bold'>{item?.teamName}</span><span>{item?.userName}</span></div></td>
                                                <td>{item?.points} </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                }
                {activeTab === 'Mine' &&
                    <>
                        <table className="table table-bordered">
                            <thead style={{ backgroundColor: "transparent" }}>
                                <tr>
                                    <th ></th>
                                    <th></th>
                                    <th colSpan={2}><h4 className='fw-bold'>Position</h4></th>
                                    <th><h4 className='fw-bold'>Points </h4></th>
                                </tr>
                            </thead>

                            <tbody id='table-body' >
                                {
                                    userContestentData?.sort((b, a) => b?.position - a?.position)?.map((item) => {
                                        return (
                                            <tr className='select-team-caption-voice-caption' >
                                                <td></td>
                                                <td width={"2%"}>{item?.position}</td>
                                                <td><img src={item?.userProfilePic ? item?.userProfilePic : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100 }} /></td>
                                                <td scope="col"><div className='d-flex flex-column '><span className='fw-bold'>{item?.teamName}</span><span>{item?.userName}</span></div></td>
                                                <td>{item?.points}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                }

            </div>
        </div>
    )
}

export default MyContests
