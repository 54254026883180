import React, { useState } from 'react'
import crossIcon from "../../assets/images/cross-icon-red.png";
import "../../styling/entercontest.css"
import https from '../../utils/axiosApi';
import { CustomAlerts, NotificationTypes } from '../CustomAlerts/CustomAlerts';
import { useNavigate } from 'react-router-dom';
import DepositModal from '../DepositModal/DepositModal';
import RulesModal from '../RulesModal/RulesModal';
const ContestCard = ({ data, type, userTeams, matchId, matchType, myContestUserTeams, cardMatchdata }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalAnimation, setModalAnimation] = useState('');

    const handleCloseModal = () => {
        setModalAnimation('modal-custom-exit-active');
        setTimeout(() => {
            setShowModal(false);
        }, 300); // Should match the duration of the CSS transition
    };

    const handleOpenModal = () => {
        setShowModal(true);
        setModalAnimation('modal-custom-enter-active');
    };



    const navigate = useNavigate()
    const updateTeam = () => {
        const teamId = data?.id;
        const teamName = data?.teamName;
        navigate(`/update-team`, { state: { teamId: teamId, teamName: teamName, matchId: matchId, cardMatchdata: cardMatchdata } });
    }
    const viewTeam = () => {
        const teamId = data?.id;
        const teamName = data?.teamName;
        navigate(`/view-team`, { state: { teamId: teamId, teamName: teamName, matchId: matchId } });
    }
    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const handleJoinContest = async (id) => {
        https.post(`/ContestApi/JoinContest?userTeamId=${selectedTeam}&contestId=${data?.id}`).then(async (res) => {
            let responseData = await res?.data;
            if (responseData?.isSuccess) {
                CustomAlerts("You have joined the contest", NotificationTypes.SUCCESS)
                setTimeout(() => {
                    setIsModalOpen(false)
                }, 1000);
            }
            else {
                CustomAlerts(responseData?.message, NotificationTypes.ERROR)
                setIsModalOpen(false)
            }

        }).catch((err) => console.log(err))
    }
    return (
        <>
            {showModal && (
                <RulesModal
                    modalAnimation={modalAnimation}
                    handleCloseModal={handleCloseModal}
                    rules={data?.rules}
                />
            )}

            

            {
                isModalOpen && <div className={`modal show`} style={{ display: 'block', width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: 'blur(5px)' }} role="dialog" >
                    <div className="modal-dialog" style={{

                    }}>
                        <div className="modal-content" style={{ height: "250px", background: "white", border: "2px solid #573497" }}>
                            <div className="modal-header" style={{ border: "none" }}>
                                <div className='modal-cross-icon' onClick={() => { setIsModalOpen(false) }}>
                                    <img src={crossIcon} alt="img-table" height={50} width={50} />
                                </div>
                            </div>

                            <div className="modal-body withdraw-modal-body">
                                <div className="tab-content" >
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 ">
                                            <h6 style={{ color: "#573497", fontWeight: "bold" }}>Enter Contest</h6>
                                        </div>
                                        <div className="col-md-12 col-sm-12 mt-3">
                                            <select className="form-select form-select-sm" aria-label=".form-select-sm example" onChange={handleTeamChange}>
                                                <option selected>Select Team</option>
                                                {userTeams?.map((item) => (
                                                    <option value={item?.id}>{item?.teamName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-12 col-sm-12 mt-4 contest-modal-btn d-flex justify-content-center align-items-center">
                                            <button className='btn btn-primary btn-block' style={{ borderRadius: "10px", marginLeft: "10px" }} onClick={handleJoinContest}>Join Contest</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div id='contest-responsive-card' style={{ cursor: "pointer" }} className="card my-3"
            >
                {
                    type == "allContesst" &&
                    <div className='card-top-header'
                        onClick={() => {
                            if (type == "allContesst") {
                                if (matchType != "upcoming") {
                                    navigate("/my-contests", { state: { matchId: data?.id } })
                                }
                                else {
                                    setIsModalOpen(true)
                                }
                            }
                            if (type == "myContest") {
                                navigate("/my-contests", { state: { matchId: matchId } })
                            }

                        }
                        }>
                        <h6>Prize Pool</h6>


                        <h6>{data?.name}</h6>
                        <h6>Entry</h6>


                    </div>
                }

                {
                    type == "myContest" && <>
                        <div className='card-top-header'>
                            <h6>{data?.contestName} </h6>
                            <h6>Prize Pool : {data?.prizePool ? data?.prizePool : 0}</h6>
                        </div></>
                }



                <div className={`d-flex justify-content-between ${type == "allContesst" ? "mt-5" : "my-5"} mx-5`} onClick={() => {
                    if (type == "allContesst") {
                        if (matchType != "upcoming") {
                            navigate("/my-contests", { state: { matchId: data?.id } })
                        }
                        else {
                            setIsModalOpen(true)
                        }
                    }
                    if (type == "myContest") {
                        navigate("/my-contests", { state: { matchId: matchId } })
                    }

                }
                }>
                    {
                        type == "allContesst" && <><h3 className='fw-bold mt-2'>PKR. {data?.prizePool}</h3>
                            <h3 className='fw-bold p-1 rounded special-item' style={{ backgroundColor: "#81459b" }} >PKR. {data?.entryFee}</h3></>
                    }
                    {
                        type == "userTeam" && <>
                            <h3 className='fw-bold mt-2'>{data?.teamName}</h3>
                            <h3 className='fw-bold px-4 rounded d-flex align-items-center special-item' style={{ backgroundColor: "#81459b" }} >{data?.matchPoints}</h3></>
                    }
                    {
                        type == "myContest" && <>
                            <div className='d-flex flex-column w-100'>
                                {
                                    data?.teams?.map((item) => {
                                        return (
                                            <div className='mt-1 d-flex w-100 justify-content-evenly align-items-center' style={{ background: "white", borderRadius: 15 }} id='my-contest-items'>
                                                <h3 className='fw-bold mt-2' style={{ color: "#431c83" }}>{item?.name}</h3>
                                                <h3 className='fw-bold mt-2' style={{ color: "#431c83" }}>{item?.points}</h3>
                                                <h3 className='fw-bold mt-2' style={{ color: "#431c83" }}>{item?.position}</h3>
                                                <h3 className='fw-bold px-4 rounded d-flex align-items-center special-item' style={{ backgroundColor: "#81459b" }} >Winnings : {item?.winnings ? item?.winnings : 0}</h3>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </>
                    }

                </div>
                {
                    type == "allContesst" &&
                    <>
                        <div className="progress-bar " style={{
                            background: "white",
                            borderRadius: "10px",
                            height: "20px",
                            width: "100%",
                            margin: "10px 0",
                            position: "relative",
                            border: "1px solid white",
                        }}>
                            <div className="progress" style={{
                                background: "#81459b",
                                height: "100%",
                                borderRadius: "10px",
                                // width: `${( data?.slots-data.remainingSlots )}%`
                                width: `${((data?.slots - data?.remainingSlots) / data?.slots) * 100}%`

                            }}></div>
                        </div>
                        <div className='d-flex justify-content-between mb-5  mx-5 mt-2' id='total-spots-area'>
                            <h3>Spots Left : {data?.remainingSlots} </h3>
                            <h3>Total Spots : {data?.slots}</h3>
                        </div>
                    </>
                }


                <div className='card-top-footer d-flex align-items-center px-5 mt-5' id='card-contest-footer'>
                    {
                        type == "allContesst" &&
                        <div className='d-flex justify-content-end align-items-end' >
                            <div className='mx-5 d-flex align-items-center justify-content-center' id='all-contest-footer' onClick={()=>{
                            navigate("/prize-details", { state: { matchId: data?.id } })
                        }}>
                                <i className="fa-solid fa-ranking-star fa-lg mx-3" style={{ color: "#653aab" }}></i><h5 className='text-dark mt-2'> PKR {data?.firstPositionPrize}</h5> <i className="fa-solid fa-trophy fa-lg  mx-3" style={{ color: "#653aab" }}></i><h5 className='text-dark  mt-2'> {data?.winningPercentage}%</h5>
                            </div>

                            <div id='see-rules-area' onClick={() => {
                                handleOpenModal()
                            }} style={{ position: "absolute", right: "10%", textDecoration: "underline" }}>
                                <h5 className='text-black'> See Rules</h5>
                            </div>
                        </div>
                    }

                    {
                        type == "userTeam" &&
                        <div className={`d-flex align-items-center justify-content-${matchType == "complete" ? "center" : "evenly"} w-100`} onClick={() => {

                        }}>
                            <h5 className='text-dark' onClick={viewTeam}>View</h5> <span>|</span>
                            {matchType == "upcoming" && <h5 className='text-dark' onClick={updateTeam}>Update</h5>}
                        </div>
                    }
                    {
                        type == "myContest" &&
                        <div className='d-flex align-items-center justify-content-center w-100'>
                            <h5 className='fw-bold' style={{ color: "#431c83" }}>View Standings</h5>
                        </div>
                    }
                </div>
            </div>



        </>
    )
}

export default ContestCard
