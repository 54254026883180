import React from 'react';
import crossIcon from "../../assets/images/cross-icon-red.png";
import "../../styling/profile.css";
import { CustomAlerts,NotificationTypes } from '../CustomAlerts/CustomAlerts';
import https from '../../utils/axiosApi';
const WithdrawMoneyModal = ({
    showModal,
    setShowModal,
    modalAnimation,
    activeTab,
    setActiveTab,
    handleCloseModal,
    handleDepositAmount,
    getAnimationStyle,
    amount,
    setAmount
}) => {
    const handleWithDraw = () => {
        let userCurrentAmount = JSON.parse(localStorage.getItem("userCurrentBalance"))
        if (Number(amount) > Number(userCurrentAmount)) {
            CustomAlerts("Insuficient Balance",NotificationTypes.ERROR)
        }
        else {
            let body ={
                amount:Number(amount)
            }
            https.post(`UserWithDrawlApi/AddUserWithDrawl?amount=${amount}`).then((res)=>{
               console.log(res);
               if(res.data.isSuccess)
               {
                 CustomAlerts("Withdrawel Successfull.",NotificationTypes.SUCCESS)
                setAmount('')
               }
               else
               {
                CustomAlerts(res.data.message,NotificationTypes.ERROR)
                setAmount('')
               }
                setTimeout(() => {
                    handleCloseModal()
                }, 1000);
            }).catch((err)=>console.log(err))
        }
    }
    return (
        <div className={`modal show ${modalAnimation}`} style={{ display: 'block', width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: 'blur(5px)' }} role="dialog"id='withdraw-model' >
            <div className="modal-dialog" style={{

            }}>
                <div className="modal-content" style={{ height: "250px" }}>
                    <div className="modal-header" style={{ border: "none" }}>
                        <div className='modal-cross-icon' onClick={handleCloseModal}>
                            <img src={crossIcon} alt="img-table" height={50} width={50} />
                        </div>
                    </div>

                    <div className="modal-body withdraw-modal-body">
                        <div className="tab-content" style={{ animation: getAnimationStyle('bankTransfer') }}>

                            <>
                                <div className={`input-modal-fields ${amount ? 'has-content' : ''} d-flex justify-content-center align-items-center`}>
                                    <input
                                        type="text"
                                        className='login-input login-full-input'
                                        style={{ height: "40px", paddingLeft: 15, color: "#7a548d", width: "96%" }}
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className='input-fields profile-btn-area p-2'>
                                    <button className='btn btn-outline-primary w-100' onClick={() => {
                                        handleWithDraw()
                                    }} style={{ borderRadius: "10px", marginLeft: "10px" }}>Withdraw Request</button>
                                </div>
                            </>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithdrawMoneyModal;
