import React from 'react';
import crossIcon from "../../assets/images/cross-icon-red.png";
import "../../styling/profile.css";
import { useNavigate } from 'react-router-dom';
const CreateTeamModal = ({
    showModal,
    setShowModal,
    modalAnimation,
    activeTab,
    setActiveTab,
    handleCloseModal,
    handleDepositAmount,
    getAnimationStyle,
    teamName,
    setTeamName
}) => {
    return (
        <div className={`modal show ${modalAnimation}`} style={{ display: 'block', width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: 'blur(5px)' }} role="dialog" >
            <div className="modal-dialog" style={{

            }}>
                <div className="modal-content" style={{ height: "250px" }}>
                    <div className="modal-header" style={{ border: "none" }}>
                        <div className='modal-cross-icon' onClick={handleCloseModal}>
                            <img src={crossIcon} alt="img-table" height={50} width={50} />
                        </div>
                    </div>

                    <div className="modal-body withdraw-modal-body">
                        <div className="tab-content" style={{ animation: getAnimationStyle('bankTransfer') }}>

                            <>
                                <h4 className='text-center fw-bold text-white'>Create Team</h4>
                                <div className={`input-modal-fie d-flex justify-content-center align-items-center`}>
                                    <input
                                        type="text"
                                        className='login-input login-full-input'
                                        placeholder='Enter Team Name'
                                        style={{ height: "40px", paddingLeft: 15, color: "#7a548d", width: "73%", borderRadius: 15, marginLeft: 5 }}
                                        value={teamName}
                                        onChange={(e) => {
                                            setTeamName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className='input-fields profile-btn-area p-2'>
                                    <button className='btn btn-outline-primary w-75' onClick={handleDepositAmount} style={{ borderRadius: "10px", marginLeft: "10px" }}>Create Team</button>
                                </div>
                            </>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTeamModal;
