import React, { useEffect, useState } from 'react';
import crossIcon from "../../assets/images/cross-icon-red.png";
import "../../styling/profile.css";
const RulesModal = ({
    modalAnimation,
    handleCloseModal,
    rules
}) => {
    return (
        <div className={`modal show ${modalAnimation}`} style={{ display: 'block', width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: 'blur(5px)' }} role="dialog" id='deposite-model'>
            <div className="modal-dialog" style={{

            }}>
                <div className="modal-content">
                    <div className="modal-header" style={{ border: "none" }}>
                        <div className='modal-cross-icon' onClick={() => {
                            handleCloseModal()
                        }}>
                            <img src={crossIcon} alt="img-table" height={50} width={50} />
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className='w-100 h-100 d-flex flex-column justify-content-start align-items-center'>
                            <div>
                                <h3 className='text-white fw-bold'>Rules</h3>
                            </div>
                            <div>
                                {
                                    rules?.map((item,index) => {
                                        return (
                                            <h6 className='text-white fw-bold my-3'>{index+1}) {item}</h6>
                                        )
                                    })
                                }

                            </div>

                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default RulesModal;
