import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginUser } from '../../redux/authSlice';
import { CustomAlerts, NotificationTypes } from '../../components/CustomAlerts/CustomAlerts';
import axios from 'axios';

const ChangePassword = () => {
  const location = useLocation();
  const userPhoneNumber = location.state.userPhoneNumber;
  const [phoneNumber, setPhoneNumber] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleChange = async () => {

    if (!password) {
      CustomAlerts("Kindly Enter Password", NotificationTypes.ERROR)
      return
    }
    let body =
    {
      "phoneNumber": userPhoneNumber,
      "password": password
    }


    axios.post(`https://dev-v11.cloudistic.net/UserApi/UpdatePassword`, body).then((res) => {
      if (res?.data?.isSuccess) {
        CustomAlerts("Password Changed Successfully", NotificationTypes.SUCCESS)
        setTimeout(() => {
          navigate("/login")
        }, 1500);
      }
    }).catch((err) => {
      console.log(err)
    })
  }








  return (
    <div className='w-100 d-flex justify-content-center align-items-center' id='login-page'>
      <div className='form-login d-flex justify-content-center align-items-center flex-column'>

        <div className='input-fields'>
          <h1 className='text-white text-center fw-bold'>Set New Password </h1>
        </div>

        <div className='input-fields'>
          <input type="text" className='login-input' placeholder='Enter New Password' value={password} onChange={(e) => { setPassword(e.target.value) }} />

        </div>


        <div className='input-fields'>
          <button className='btn btn-outline-primary' onClick={handleChange}>Save Password</button>

        </div>


      </div>
    </div>
  )
}

export default ChangePassword
