import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    msg: "",
    user: "",
    token: "",
    loading: false,
    error: ""
}

export const signUpUser = createAsyncThunk('signupuser', async (body) => {
    const res = await fetch("api_url", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })

    return await res.json()
});

export const loginUser = createAsyncThunk('loginuser', async (body) => {
    const res = await fetch("https://dev-v11.cloudistic.net/UserApi/SignInByMobileUser", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })

    return await res.json()
});

const authSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        addToken: (state, action) => {
            state.token = action.payload; // Assuming you are dispatching with payload containing the token
        },
        addUser: (state, action) => {
            state.user = action.payload; // Assuming you are dispatching with payload containing the user
        },
        logout: (state, action) => {
            state.token = null;
            state.user = null;
            localStorage.clear();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state, { payload }) => {
                state.loading = false;
                if (payload.error) {
                    state.error = payload.error;
                } else {
                    state.msg = payload.msg;
                    state.token = payload?.data?.token;
                    state.user = payload;

                    localStorage.setItem('msg', payload?.data?.msg);
                    localStorage.setItem('user', JSON.stringify(payload?.data));
                    localStorage.setItem('token', payload?.data?.token);
                }
            })
            .addCase(loginUser.rejected, (state) => {
                state.loading = false;
                // Handle rejected case if needed
            })
            .addCase(signUpUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(signUpUser.fulfilled, (state, { payload }) => {
                state.loading = false;
                if (payload.error) {
                    state.error = payload.error;
                } else {
                    state.msg = payload.msg;
                }
            })
            .addCase(signUpUser.rejected, (state) => {
                state.loading = false;
                // Handle rejected case if needed
            });
    }
});

export const { addToken, addUser, logout } = authSlice.actions;
export default authSlice.reducer;
