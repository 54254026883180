import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className="footer text-light" style={{ marginTop: 150 }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">

                        <a href="https://www.facebook.com/profile.php?id=100064040531805" target="_blank">

                            <i className="fa-brands fa-facebook fa-2xl mx-2 text-white"></i>
                        </a>

                        {/* <i className="fa-brands fa-twitter fa-2xl mx-2"></i>
                        <i className="fa-brands fa-youtube fa-2xl mx-2"></i> */}


                        <a href="https://www.tiktok.com/@virtual11fantasy?fbclid=IwAR1-W4HEzzJ2EZdbdUtJCabWMtDmo0ec0KhYWYgeVc1KIsnfMSDkuc8RR7k" target="_blank">
                            <i className="fa-brands fa-tiktok fa-2xl mx-2  text-white"></i>
                        </a>
                        <a href="https://chat.whatsapp.com/DrzbIxLQXsyLXhqHYGvpIo" target="_blank">
                            <i className="fa-brands fa-whatsapp fa-2xl mx-2 text-white"></i>
                        </a>

                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end tagline">
                        <h6 className='fw-bold text-center'>ALL RIGHTS RESERVED &copy; {currentYear} VIRTUAL-11.COM</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
