import React, { useState, useEffect } from 'react';
import UpcomingTournments from '../../components/UpcomingTournments/UpcomingTournments';
import InprogrssTournments from '../../components/InprogrssTournments/InprogrssTournments';
import CompletedTournments from '../../components/CompletedTournments/CompletedTournments';


const Home = () => {
  const [activeTab, setActiveTab] = useState('upcoming');



  useEffect(() => {
    if (window.location.pathname == "/home") {
      document.body.style.background = `linear-gradient(to bottom, rgba(151, 112, 220, 255), rgba(87, 51, 150, 255))`
    }

  }, []);
  return (
    <div className='my-5' id='home-tabs'>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'upcoming' ? 'active' : ''}`}
            onClick={() => setActiveTab('upcoming')}
            type="button"
            aria-controls="home"
            aria-selected={activeTab === 'upcoming'}
          >
            Upcoming
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'inprogress' ? 'active' : ''}`}
            onClick={() => setActiveTab('inprogress')}
            type="button"
            aria-controls="profile"
            aria-selected={activeTab === 'inprogress'}
          >
            In Progress
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`}
            onClick={() => setActiveTab('completed')}
            type="button"
            aria-controls="contact"
            aria-selected={activeTab === 'completed'}
          >
            Completed
          </button>
        </li>
      </ul>

      <div className="tab-content my-5" id="myTabContent">
        <div
          className={`tab-pane fade ${activeTab === 'upcoming' ? 'show active' : ''}`}
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <UpcomingTournments isActive={activeTab === 'upcoming'} matchType={"upcoming"} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'inprogress' ? 'show active' : ''}`}
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <InprogrssTournments isActive={activeTab === 'inprogress'} matchType={"inprogress"} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'completed' ? 'show active' : ''}`}
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <CompletedTournments isActive={activeTab === 'completed'} matchType={"complete"} />
        </div>
      </div>
    </div>
  );
}

export default Home
