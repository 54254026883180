import React, { useEffect, useState } from 'react';
import './points.css';
import { useLocation } from 'react-router-dom';
import https from '../../utils/axiosApi';

const PrizeDetails = () => {
  const location = useLocation();
  let contestID = location.state.matchId

  const [prizesData, setPrizesData] = useState([])
  const fetchPrizes = async () => {
    try {
      const response = await https.get(`/ContestPrizeApi/GetContestPrizesByContest?contestId=${contestID}`);
      const resData = response?.data?.data;
      setPrizesData(resData)
      console.log(resData)
    } catch (error) {
      console.error('Error fetching transaction history:', error);

    }
  }

  useEffect(() => {
    fetchPrizes();
  }, []);
  return (
    <div className='container my-5 points-sys' id='points-eco'>
      <div className='tab-menu'>
        <button >
          Prize Details
        </button>

      </div>
      <div className='tab-content my-4'>
        {
          prizesData?.map((item, index) => {
            return (
              <div className='tab-menu my-3'>
                <button className='d-flex justify-content-center align-items-center flex-column'>
                  {
                    index == 0 ? "1st Position" : index == 1 ? "2nd Position" : index == 2 ? "3rd Position" : index == 3 ? `From Position : ${item?.startPosition}, to Position ${item?.endPosition}` : index == 4 ? `From Position : ${item?.startPosition},  to Position ${item?.endPosition}` : ""}



                  <div style={{ background: "#431c83", color: "white", padding: "15px 60px", marginTop: 10, borderRadius: 10 }}>
                    Prize : {item?.prize} Pkr
                  </div>
                </button>
              </div>
            )
          })
        }

      </div>
    </div>
  );
};

export default PrizeDetails;
