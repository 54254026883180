import React, { useEffect, useState } from 'react';
import https from '../../utils/axiosApi';
import ContestCard from '../ContestCard/ContestCard';
import "../../styling/entercontest.css"

const EnterContest = ({ matchId, matchType, setOldActiveTab, cardMatchdata }) => {
  const [activeTab, setActiveTab] = useState('Contests');
  const [allContestByIdData, setAllContestByIdData] = useState([])
  const [userTeamByMatchIdData, setUserTeamByMatchIdData] = useState([])
  const [myContestData, setMyContestData] = useState([])


  const [isAllContestLoader, setIsAllContestLoader] = useState(false)
  const [isUserTeamLoader, setIsUserTeamLoader] = useState(false)
  const [isMyContestLoader, setIsMyContestLoader] = useState(false)
  const handleTabChange = (tab) => {

    setActiveTab(tab);
  };

  const fetchAllContestByMatchId = async () => {
    setIsAllContestLoader(true)
    https.get(`ContestApi/GetContestByMatch?matchId=${matchId}`).then(async (res) => {
      let upcomingData = await res?.data?.data;
      setAllContestByIdData(upcomingData)
      setIsAllContestLoader(false)
    }).catch((e) => {
      setIsAllContestLoader(false)
      console.log(e)
    }
    )

  }


  // 
  const fetchUserTeamByMatchId = async () => {
    setIsUserTeamLoader(true)
    https.get(`/ContestApi/GetUserTeamsForMatchWithPoints?matchId=${matchId}`).then(async (res) => {
      let userTeam = await res?.data?.data;
      setUserTeamByMatchIdData(userTeam)
      setIsUserTeamLoader(false)
    }).catch((e) => {
      setIsUserTeamLoader(false)
      console.log(e)
    })

  }
  const fetchMyContestByMatchId = async () => {
    setIsMyContestLoader(true)
    https.get(`/ContestApi/GetContestGroupedByTeamByMatchForUser?matchId=${matchId}`).then(async (res) => {
      let userTeam = await res?.data?.data;

      setMyContestData(userTeam)
      setIsMyContestLoader(false)
    }).catch((e) => {
      setIsMyContestLoader(false)
      console.log(e)
    })

  }

  useEffect(() => {
    switch (activeTab) {
      case 'Contests':
        fetchAllContestByMatchId();
        break;
      case 'Teams':
        fetchUserTeamByMatchId();
        break;
      case 'My Contests':
        fetchMyContestByMatchId();
        break;
      default:
        break;
    }
  }, [activeTab])

  useEffect(() => {

    fetchAllContestByMatchId();
    fetchUserTeamByMatchId();
    fetchMyContestByMatchId();
  }, [])

  return (
    <>
    {
      console.log("contest data :: ",allContestByIdData)
    }
      <div className="tab-menu" id='enter-contest-tabs'> 
        <button onClick={() => handleTabChange('Contests')} className={activeTab === 'Contests' ? 'active-btn' : ''}>
          Contests
        </button>
        <button onClick={() => handleTabChange('Teams')} className={activeTab === 'Teams' ? 'active-btn' : ''}>
          Teams
        </button>
        <button onClick={() => handleTabChange('My Contests')} className={activeTab === 'My Contests' ? 'active-btn' : ''}>
          My Contests
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'Contests' &&

          (allContestByIdData?.length ?
            allContestByIdData?.map((item) => {
              return (
                <ContestCard data={item} type={"allContesst"} userTeams={userTeamByMatchIdData} matchType={matchType} />)
            }) : <div className='fw-bold text-center text-white mt-4'>No Contest Found Yet</div>)
        }
        {activeTab === 'Teams' &&
          userTeamByMatchIdData?.map((item, index) => {
            return (
              <>
                {(matchType == "upcoming" && index == 0) && <button className='btn btn-outline-primary mt-3 mb-0' id='create-team-btn' onClick={() => {
                  setOldActiveTab(2)
                }}>
                  Create Team</button>}
                <ContestCard data={item} matchId={matchId} type="userTeam" matchType={matchType} cardMatchdata={cardMatchdata} />
              </>
            )
          })}
        {activeTab === 'My Contests' &&
          (myContestData?.length ?
            myContestData?.map((item) => {
              return (
                <ContestCard data={item} type="myContest" matchId={item?.contestId} matchType={matchType} />
              )
            }) : <div className='fw-bold text-center text-white mt-4'>No Contest Found Yet</div>)
        }
      </div>
    </>
  );
};

export default EnterContest;
