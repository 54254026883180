import React from 'react'

const Loader = ({ size }) => {
  return (
    <div className={`spinner-border text-white ${size && "spinner-border-sm"}`} role="status">
    </div>
  )
}

export default Loader
