import { Navigate, Outlet } from 'react-router-dom';
import ROUTES from './RoutePath';

const PrivateRoutes = () => {

  let auth = localStorage.getItem("token");
  return (auth ? <Outlet /> : <Navigate to={"/login"}/>)
};

export default PrivateRoutes;
