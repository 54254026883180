import React, { useEffect, useState } from 'react';
import crossIcon from "../../assets/images/cross-icon-red.png";
import "../../styling/profile.css";
import https from '../../utils/axiosApi';
import { CustomAlerts, NotificationTypes } from '../CustomAlerts/CustomAlerts';
const DepositModal = ({
    showModal,
    setShowModal,
    modalAnimation,
    activeTab,
    setActiveTab,
    handleCloseModal,
    handleDepositAmount,
    getAnimationStyle,
    amount,
    setAmount

    // ... any other props you need
}) => {
    const [adminAccountDetails, setAdminAccountDetails] = useState([])
    const [file, setFile] = useState(null)
    const [depositPicture, setDepositePicture] = useState(null)

    const fetchAdminData = async () => {
        https.get("/AdminAccountApi/GetAllAdminAccount").then(async (res) => {
            let data = await res?.data?.data[0];
            setAdminAccountDetails(data)
        }).catch((err) => {
            console.log({ err })
        })
    }


    useEffect(() => {
        fetchAdminData()
    }, [])


    const handleDepositUserAmount = () => {
        const formData = new FormData();
        formData.append('Amount', amount);
        formData.append('AccountId', 1);
        // formData.append('file', file);

        // console.log(amount,file)
        // console.log(formData)
        // https.post("/UserDepositApi/AddUserDepositWithFile", formData).then(async (res) => {
        //     console.log("res , ", res)
        // }).catch((err) => {
        //     console.log({ err })
        // })
    }

    const handleIconClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.match('image.*')) {
            setDepositePicture(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setFile(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleUserDeposit = () => {
        if (!file) {
            CustomAlerts("No file selected", NotificationTypes.ERROR);
            return;
        }
        const formData = new FormData();
        formData.append('file', depositPicture);
        if (amount) {
            formData.append('Amount', amount);
            formData.append('AccountId', 1);
        }
        for (let pair of formData?.entries()) {
        }

        https.post("/UserDepositApi/AddUserDepositWithFile", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            CustomAlerts("Upload successful", NotificationTypes.SUCCESS);
            setTimeout(() => {
                setDepositePicture(null)
                setFile(null)
                setAmount(null)
                handleCloseModal()
            }, 1000);
        }).catch(error => {
            console.error("Upload failed", error);
        });

    }
    return (
        <div className={`modal show ${modalAnimation}`} style={{ display: 'block', width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: 'blur(5px)' }} role="dialog" id='deposite-model'>
            <div className="modal-dialog" style={{

            }}>
                <div className="modal-content" style={{ height: file && 600 }}>
                    <div className="modal-header" style={{ border: "none" }}>
                        <div className='modal-cross-icon' onClick={() => {
                            setFile(null)
                            setAmount(null)
                            handleCloseModal()
                        }}>
                            <img src={crossIcon} alt="img-table" height={50} width={50} />
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className="tabs">
                            <button
                                className={activeTab === 'bankTransfer' ? 'active' : ''}
                                onClick={() => setActiveTab('bankTransfer')}
                                style={{
                                    background: activeTab === 'bankTransfer' ? '#9255b4' : '#ffffff',
                                    color: activeTab === 'bankTransfer' ? '#ffffff' : '#9255b4',
                                    border: activeTab === 'bankTransfer' ? '1px solid #ab7bc3' : '#ffffff',
                                    fontWeight: "bold"
                                }}
                            >
                                Bank Transfer
                            </button>
                            <button
                                className={activeTab === 'card' ? 'active' : ''}
                                onClick={() => setActiveTab('card')}

                                style={{
                                    background: activeTab === 'card' ? '#9255b4' : '#ffffff',
                                    color: activeTab === 'card' ? '#ffffff' : '#9255b4',
                                    border: activeTab === 'card' ? '1px solid #ab7bc3' : '#ffffff',
                                    fontWeight: "bold"
                                }}
                            >
                                Credit/Debit Card
                            </button>
                        </div>

                        {/* Tab content */}
                        <div className="tab-content" style={{ animation: getAnimationStyle('bankTransfer') }}>
                            {activeTab === 'bankTransfer' && (
                                <>
                                    <div className='w-100 mt-4' style={{ background: "white", borderRadius: "10px", padding: 15 }}>
                                        <h6>Bank Account : {adminAccountDetails?.bankName}</h6>
                                        <h6>Title : {adminAccountDetails?.acTitle}</h6>
                                        <h6>Acc Number : {adminAccountDetails?.acNo}</h6>
                                    </div>
                                    <div className={`input-modal-fields ${amount ? 'has-content' : ''}`}>
                                        <input
                                            type="text"
                                            className='login-input login-full-input w-100'
                                            style={{ height: "30px", paddingLeft: 15, color: "#7a548d" }}
                                            value={amount}
                                            onChange={(e) => {
                                                setAmount(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {
                                        file && <div className={`my-2`}>
                                            <img src={file} width={"100%"} height={200} style={{ borderRadius: 10 }} />
                                        </div>
                                    }
                                    <div className='input-fields profile-btn-area p-2'>
                                        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
                                        <button className='btn btn-outline-primary' style={{ borderRadius: "10px" }} onClick={handleIconClick}>Update Image</button>


                                        <button className='btn btn-outline-primary' onClick={handleUserDeposit} style={{ borderRadius: "10px", marginLeft: "10px" }}>Deposit Amount</button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="tab-content" style={{ animation: getAnimationStyle('card') }}>
                            {activeTab === 'card' && (
                                <div className='text-white text-center mt-3 fw-bold'>Coming Soon</div>
                            )}
                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default DepositModal;
