import React, { useEffect, useState } from 'react';
import SelectTeamTab from '../../components/SelectTeamTab/SelectTeamTab';
import SelectTournments from '../../components/SelectTournments/SelectTournments';
import SelectMatch from '../../components/SelectMatch/SelectMatch';
import EnterContest from '../../components/EnterContest/EnterContest';
import UpcomingTournments from '../../components/UpcomingTournments/UpcomingTournments';
import { useLocation } from 'react-router-dom';
import https from '../../utils/axiosApi';
import { CustomAlerts, NotificationTypes } from '../../components/CustomAlerts/CustomAlerts';

const MatchUpdates = () => {
    const [activeTab, setActiveTab] = useState(2);
    const location = useLocation();
    const [userTeamLength, setUserTeamLength] = useState(null)
    const [isSelectTeamTabDisable, setIsSelectTeamTabDisable] = useState(false)
    const data = location.state?.tournamentData;
    const matchType = location.state?.matchType;
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    const fetchUserTeamByMatchId = async () => {
        https.get(`/ContestApi/GetUserTeamsForMatchWithPoints?matchId=${data?.id}`).then(async (res) => {

            let userTeam = await res?.data?.data;

            setUserTeamLength(userTeam.length)
            if (userTeam.length > 0) {
                setActiveTab(3)
            }
        }).catch((e) => {
            console.log(e)
        })

    }

    useEffect(() => {
        fetchUserTeamByMatchId()
    }, [])

    return (
        <div className='my-5 main-info-container w-100 d-flex justify-content-center align-items-center flex-column'>
            <div id='match-updates-container'>
                <hr className='horizontal-line' />
                <div className={`tabs tabs-1 d-flex justify-content-center align-items-center flex-column`}>
                    <div className={`tabs-number ${activeTab >= 1 ? 'active-tab' : ''}`} onClick={() => handleTabClick(1)}>
                        1
                    </div>
                    <h6 className='tab-text-match-updates'>Selected Tournment</h6>
                </div>

                <div className={`tabs tabs-2 d-flex justify-content-center align-items-center flex-column`}>
                    <div className={`tabs-number ${activeTab >= 2 ? 'active-tab' : ''}`} onClick={() => {
                        if (matchType != "upcoming") {
                            CustomAlerts("Can't Create Past Match Team", NotificationTypes.ERROR)
                        }
                        else {
                            handleTabClick(2)
                        }

                    }} >
                        2
                    </div>
                    <h6 className='tab-text-match-updates'>Create Team</h6>
                </div>

                <div className={`tabs tabs-3  d-flex justify-content-center align-items-center flex-column`}>
                    <div className={`tabs-number ${activeTab >= 3 ? 'active-tab' : ''}`} onClick={() => handleTabClick(3)}>
                        3
                    </div>
                    <h6 className='tab-text-match-updates'>Enter Contest</h6>
                </div>
            </div>

            <div className='main-container-matches my-3' style={{ width: "80%" }}>
                {activeTab === 1 && <SelectTournments data={data} />}

                {activeTab === 2 &&
                    <>
                        <SelectTeamTab data={data} setPrevActiveTab={setActiveTab} />
                    </>}
                {activeTab === 3 && <EnterContest matchId={data?.id} matchType={matchType} setOldActiveTab={setActiveTab} cardMatchdata={data} />}
            </div>
        </div>
    );
}

export default MatchUpdates;
