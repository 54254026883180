import React, { useEffect, useState } from 'react';
import SelectTeamTab from '../../components/SelectTeamTab/SelectTeamTab';
import SelectTournments from '../../components/SelectTournments/SelectTournments';
import SelectMatch from '../../components/SelectMatch/SelectMatch';
import EnterContest from '../../components/EnterContest/EnterContest';
import UpcomingTournments from '../../components/UpcomingTournments/UpcomingTournments';
import { useLocation } from 'react-router-dom';
import https from '../../utils/axiosApi';
import UpdateTeamTab from '../../components/UpdateTeamTab.js/UpdateTeamTab';
import { CustomAlerts, NotificationTypes } from '../../components/CustomAlerts/CustomAlerts';

const UpdateTeam = () => {
    const [activeTab, setActiveTab] = useState(2);
    const location = useLocation();
    const [isSelectTeamTabDisable, setIsSelectTeamTabDisable] = useState(false)
    const teamId = location.state?.teamId;
    const teamName = location.state?.teamName;
    const matchId = location.state?.matchId;
    const cardMatchdata = location.state?.cardMatchdata;
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    return (
        <div className='my-5 main-info-container w-100 d-flex justify-content-center align-items-center flex-column'>
            <div id='match-updates-container'>
                <hr className='horizontal-line' />
                <div className={`tabs tabs-1 d-flex justify-content-center align-items-center flex-column`}>
                    <div className={`tabs-number ${activeTab >= 1 ? 'active-tab' : ''}`} onClick={() => {
                        CustomAlerts("Only Update Team", NotificationTypes.WARNING)
                    }}>
                        1
                    </div>
                    <h6>Selected Tournment</h6>
                </div>

                <div className={`tabs tabs-2 d-flex justify-content-center align-items-center flex-column`}>
                    <div className={`tabs-number ${activeTab >= 2 ? 'active-tab' : ''}`} onClick={() => {
                        handleTabClick(2)
                    }} >
                        2
                    </div>
                    <h6>Update Team</h6>
                </div>

                <div className={`tabs tabs-3  d-flex justify-content-center align-items-center flex-column`}>
                    <div className={`tabs-number ${activeTab >= 3 ? 'active-tab' : ''}`} onClick={() => {
                        CustomAlerts("Only Update Team", NotificationTypes.WARNING)
                    }}>
                        3
                    </div>
                    <h6>Enter Contest</h6>
                </div>
            </div>

            <div className='main-container-matches my-3' style={{ width: "80%" }}>

                {activeTab === 2 &&
                    <>
                        <UpdateTeamTab selectedmatchId={matchId} selectedTeamId={teamId} selectedTeamName={teamName} cardMatchdata={cardMatchdata}/>
                    </>}

            </div>
        </div>
    );
}

export default UpdateTeam;
