import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home/Home';
import PrivateRoutes from "./routes/PrivateRoutes";
import Profile from './pages/Profile/Profile';
import Header2 from './components/Header2/Header2';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import MatchUpdates from './pages/MatchUpdates.js/MatchUpdates';
import UIElements from './pages/UIElements';
import Footer from "./components/Footer/Footer"
import MyContests from './pages/MyContests/MyContests';
import VerificationScreen from './pages/VerificationScreen/VerificationScreen';
import UpdateTeam from './pages/UpdateTeam/UpdateTeam';
import ViewTeam from './pages/ViewTeam/ViewTeam';
import backgroundImage from "./assets/images/bg-for-login.png"
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import VerificationForgetPassowrd from './pages/VerificationForgetPassowrd/VerificationForgetPassowrd';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import PointsSystem from './pages/PointsSystem/PointsSystem';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TransactionHistory from './pages/TransactionHistory/TransactionHistory';
import PrizeDetails from './pages/PrizeDetails/PrizeDetails';
function App() {
  // useEffect  (() => {
  //   if (window.location.pathname === "/") {
  //     window.history.pushState(null, null, window.location.pathname);
  //     window.addEventListener("popstate", function () {
  //       window.history.pushState(null, null, window.location.pathname);
  //     });
  //   }
  // }, [])


  
  return (
    <div>
      <Header2 />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path={"/"} element={<Home />} exact />
          <Route path={"/home"} element={<Home />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/match-updates"} element={<MatchUpdates />} />
          <Route path={"/update-team"} element={<UpdateTeam />} />
          <Route path={"/view-team"} element={<ViewTeam />} />
          <Route path="/match-updates/:selectedTeamId/:selectedTeamName" element={<MatchUpdates />} />
          {/* <Route path={"/ui"} element={<UIElements />} /> */}
          <Route path={"/my-contests"} element={<MyContests />} />
          <Route path={"/transaction-history"} element={<TransactionHistory />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/points-system" element={<PointsSystem />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/verification" element={<VerificationScreen />} />
        <Route path="/verify-forget-password-otp" element={<VerificationForgetPassowrd />} />
        <Route path="/forgot-password" element={<ForgetPassword/>} />
        <Route path="/change-password" element={<ChangePassword/>} />
        <Route path="/prize-details" element={<PrizeDetails />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
