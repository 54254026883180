import React, { useState, useEffect, useRef } from 'react'
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import TournmentsCards from '../TournmentsCards/TournmentsCards';
import https from '../../utils/axiosApi';
import { useNavigate } from 'react-router-dom';


SwiperCore.use([Navigation, Pagination]);
const UpcomingTournments = ({ isActive, matchType }) => {
    const swiperRef = React.useRef(null);
    const [showBackArrow, setShowBackArrow] = React.useState(true);
    const [upcomingTournmentData, setUpcomingTournmentData] = useState([])
    const navigation = useNavigate()
    const getSlidesPerView = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 640) {
            return 1;
        } else if (screenWidth < 1024) {
            return 2;
        } else {
            return 2;
        }
    };

    const slidesPerView = getSlidesPerView();
    const showArrows = slidesPerView > 1;

    const handleSlideChange = (swiper) => {
        setShowBackArrow(swiper.activeIndex >= 2);
    };

    useEffect(() => {
        swiperRef.current.swiper.on('slideChange', handleSlideChange);
    }, []);

    const fetchUpcomingTournmentsData = async () => {
        https.get("/MatchApi/GetUpcomingMatches").then(async (res) => {

            let upcomingData = await res?.data?.data;
            setUpcomingTournmentData(upcomingData)
        }).catch((e) => console.log(e))

    }

    useEffect(() => {
        if (isActive) {
            fetchUpcomingTournmentsData();
        }
    }, [isActive]);

    return (
        <>
            <Swiper
                ref={swiperRef}
                spaceBetween={20}
                slidesPerView={slidesPerView}
                loop={false} // Disable the loop
                navigation={showArrows}
                pagination={{
                    clickable: true,
                    renderBullet: function (index, className) {
                        return `<span className="${className} bg-white" />`; // Customize the pagination dots
                    },
                }}
            >

                {
                    upcomingTournmentData?.length ?
                        upcomingTournmentData?.map((item, index) =>
                            <SwiperSlide key={index}>
                                <TournmentsCards data={item} matchType={matchType} />
                            </SwiperSlide>
                        )

                        : <p className='text-white text-center'>No Tournments Found</p>
                }

            </Swiper>
        </>
    )
}

export default UpcomingTournments
