import React, { useState, useEffect, useRef } from 'react'
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import https from '../../utils/axiosApi';
import InprogressTournmentsCards from '../InprogressTournmentsCards/InprogressTournmentsCards';


SwiperCore.use([Navigation, Pagination]);
const InprogrssTournments = ({ isActive,matchType }) => {
    const swiperRef = React.useRef(null);
    const [showBackArrow, setShowBackArrow] = React.useState(true);
    const [inprogressTournmentData, setInprogressTournmentData] = useState([])
    const getSlidesPerView = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 640) {
            return 1;
        } else if (screenWidth < 1024) {
            return 2;
        } else {
            return 2;
        }
    };

    const slidesPerView = getSlidesPerView();
    const showArrows = slidesPerView > 1;

    const handleSlideChange = (swiper) => {
        setShowBackArrow(swiper.activeIndex >= 2);
    };

    useEffect(() => {
        swiperRef.current.swiper.on('slideChange', handleSlideChange);
    }, []);

    const fetchInprogressTournmentsData = async () => {

        https.get("/MatchApi/GetInProgressMatches").then(async (res) => {
            let upcomingData = await res?.data?.data;
            setInprogressTournmentData(upcomingData)
        }).catch((e) => console.log(e))

    }

    useEffect(() => {
        if (isActive) {
            fetchInprogressTournmentsData();
        }
    }, [isActive]);

    
    return (
        <>
            <Swiper
                ref={swiperRef}
                spaceBetween={20}
                slidesPerView={slidesPerView}
                loop={false} // Disable the loop
                navigation={showArrows}
                pagination={{
                    clickable: true,
                    renderBullet: function (index, className) {
                        return `<span className="${className} bg-white" />`; // Customize the pagination dots
                    },
                }}
            >

                {
                    inprogressTournmentData?.length ?
                        inprogressTournmentData?.map((item, index) =>
                            <SwiperSlide key={index}>
                                <InprogressTournmentsCards data={item} matchType={matchType}/>
                            </SwiperSlide>
                        )

                        : <p className='text-white text-center'>No Tournments Found</p>
                }

            </Swiper>
        </>
    )
}

export default InprogrssTournments
