import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginUser } from '../../redux/authSlice';
import { CustomAlerts, NotificationTypes } from '../../components/CustomAlerts/CustomAlerts';
import axios from 'axios';
import backgroundImage from "../../assets/images/bg-for-login.png"
const VerificationScreen = () => {
    const [pins, setPins] = useState(['', '', '', '']);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pinInputs = useRef([]);
    const location = useLocation();
    const phoneNumber = location.state.phoneNumber;
    const handleVerify = async () => {
        // Combine pins into a single string for verification
        const verificationCode = pins.join('');
        axios.get(`https://dev-v11.cloudistic.net/UserApi/VerifyOtp?phoneNo=${phoneNumber}&otp=${verificationCode}`).then((res) => {


            if (!res?.data?.isSuccess) {
                CustomAlerts(res?.data?.message, NotificationTypes.ERROR)
            }
            else {
                CustomAlerts("Account Created Successfully", NotificationTypes.SUCCESS)
                navigate("/login")
            }
        }).catch((err) => {
            console.log(err)
        })
    };


   
    const handleChange = (index, value) => {
        // Update the pin at the specified index

        if (!/^\d*$/.test(value)) {
            return;
        }
        const newPins = [...pins];
        newPins[index] = value;

        // Move to the next input if the current input has a value
        if (value && index < 3) {
            pinInputs.current[index + 1].focus();
        } else if (!value && index > 0) {
            // Move to the previous input if the current input is empty
            pinInputs.current[index - 1].focus();
        }

        // Update the state
        setPins(newPins);
    };
    const handlePaste = (e) => {
        e.preventDefault();

        // Get the pasted text
        const pastedText = e.clipboardData?.getData('text');

        // Update the state with the pasted text
        const newPins = Array.from(pastedText).slice(0, 4);
        setPins(newPins);

        // Focus on the last input
        pinInputs.current[pins.length - 1].focus();
    };

    return (
        <div className='w-100 d-flex justify-content-center align-items-center' id='verification-container'>
            <div className='form-login d-flex justify-content-center align-items-center flex-column'>

                <div className='input-fields'>
                    <h1 className='text-white text-center fw-bold'>Verification Code</h1>
                </div>

                <div className="form-row d-flex" onPaste={handlePaste}>
                    {pins?.map((pin, index) => (
                        <input
                            key={index}
                            type="text"
                            className="form-control pin-input"
                            maxLength="1"
                            value={pin}
                            onChange={(e) => handleChange(index, e.target.value)}
                            ref={(input) => (pinInputs.current[index] = input)}
                            required
                        />
                    ))}
                </div>


                <div className='input-fields d-flex flex-column' >
                    <h6 className='text-white'>We have OTP to your number <span className='text-decoration-underline'>{phoneNumber}</span></h6>
                    <button className='btn btn-outline-primary' onClick={handleVerify}>
                        Verify
                    </button>
                </div>

            </div>
        </div>
    );
};

export default VerificationScreen;
