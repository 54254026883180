import React, { useState } from 'react';
import CounterDown from '../CounterDown/CounterDown';
import { useNavigate } from 'react-router-dom';

const InprogressTournmentsCards = ({ data, matchType }) => {
    const navigate = useNavigate()
    return (
        <div className='tournament-box' id='inprogress-tournment-cards' onClick={() => {
            navigate("/match-updates", { state: { tournamentData: data, matchType: matchType } });
            // localStorage.setItem("tournamentData",JSON.parse(data))
        }
        }>
            <div className='top-area'>
                <span className='text'>{data?.name}</span>
            </div>
            <div className='center-area'>
                <div className='center-area-box'>
                    <div className='center-area-img'>
                        <img
                            src={data?.team1Logo}
                            alt="team1Logo"
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                        />
                    </div>

                    <div>
                        <h6>{data?.team1Name}</h6>
                    </div>
                </div>
                <div className='center-area-box'>
                    <div>
                        <h6>{data?.team1Score ? data?.team1Score +" - "+ data.team1Overs : "Yet to bat"} | {data?.team2Score ? data?.team2Score +" - "+ data.team2Overs : "Yet to bat"}</h6>
                    </div>
                </div>
                <div className='center-area-box'>
                    <div className='center-area-img'>
                        <img
                            src={data?.team2Logo}
                            alt="team1Logo"
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                        />
                    </div>
                    <div>
                        <h6>{data?.team2Name}</h6>
                    </div>
                </div>
            </div>
            <div className='bottom-area' style={{
                width: "90%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: "center",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                background: "transparent"

            }}>
                <h6 className='mt-1 text-center' style={{ fontSize: "12px" }}>{data?.resultDescription}</h6>
            </div>
        </div>
    )
}

export default InprogressTournmentsCards;
