import React from 'react'
import TournmentsCards from '../TournmentsCards/TournmentsCards'
import UpcomingTournments from '../UpcomingTournments/UpcomingTournments'

const SelectTournments = ({data}) => {
  
  return (
    <div>
      <TournmentsCards data={data} />
    </div>
  )
}

export default SelectTournments
