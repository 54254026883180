import React, { useEffect, useState } from 'react'
import plusIcon from "../../assets/images/pluc-icon-green.png"
import crossIcon from "../../assets/images/cross-icon-red.png"

import "../../styling/selectTeam.css"

import personImage from "../../assets/images/person.jpeg"
import https from '../../utils/axiosApi'
import Loader from '../Common/Loader'
import { toast } from 'react-toastify'
import CreateTeamModal from '../CreateTeamModal/CreateTeamModal'

import defaultPlayerImage from "../../assets/images/cricket-player.png"
import { CustomAlerts, NotificationTypes } from '../CustomAlerts/CustomAlerts'



import gloves_unactive from "../../assets/images/Glove Wte.png"
import batsman_unactive from "../../assets/images/Shoo1t Wte.png"
import hitter_unactive from "../../assets/images/Shoot Wte.png"
import baller_unactive from "../../assets/images/Ball White.png"
const SelectTeamTab = ({ data, setPrevActiveTab }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalBudget, setTotalBudget] = useState(100)

    const [allPlayersByMatchData, setAllPlayersByMatchData] = useState([])

    const [playerSelections, setPlayerSelections] = useState({});
    const [finalTeamPlayers, setFinalTeamPlayers] = useState([]);
    const [selectedCaptain, setSelectedCaptain] = useState(null);
    const [selectedViceCaptain, setSelectedViceCaptain] = useState(null);
    const [isCaptainVoiceCaptionSelection, setIsCaptainVoiceCaptionSelection] = useState(false)
    const [showModal1, setShowModal1] = useState(false);

    const [modalAnimation, setModalAnimation] = useState('');
    const [teamName, setTeamName] = useState("")
    const [totalWicketKeepers, setTotalWicketKeepers] = useState([])
    const [totalBatsman, setTotalBatsman] = useState([])
    const [hitter, setHitter] = useState([])
    const [baller, setBaller] = useState([])
    const [isUpdatingTeam, setIsUpdatingTeam] = useState(false);
    const [UpdatedTeamId, setUpdatedTeamId] = useState(null);
    const [createdUserTeam, setCreatedUserTeam] = useState(null);

    const [team1Count, setTeam1Count] = useState(0);
    const [team2Count, setTeam2Count] = useState(0);


    const [team1Id, setTeam1Id] = useState(null)
    const [team2Id, setTeam2Id] = useState(null)

    const handleTabClick = (tabNumber) => {
        setLoading(true); // Set loading to true
        setTimeout(() => {
            setActiveTab(tabNumber);
            setLoading(false); // Set loading to false after 1 second
        }, 500);
    };

    const handleCloseModal1 = () => {
        setModalAnimation('modal-custom-exit-active');
        setTimeout(() => {
            setShowModal1(false);
        }, 300); // Should match the duration of the CSS transition
    };

    const getAnimationStyle = (tabName) => {
        return activeTab === tabName ? 'fadeIn 0.5s' : 'fadeOut 0.5s';
    };



    // const fetchPlayersByMatchWithUserTeam = async () => {
    //     try {
    //         // Get user team player IDs
    //         const userTeamPlayerIds = new Set(await getUserTeam());
    //         console.log("matchchhhi ", data?.id)
    //         const response = await https.get(`/TournamentTeamPlayerApi/GetPlayersByMatch?matchId=${data.id}`);
    //         console.log("match id : ", data?.id)
    //         let allPlayersData = response?.data?.data;
    //         let mergedArray = allPlayersData?.team1Players?.concat(allPlayersData.team2Players);

    //         // Create initial selections based on user team data
    //         const initialSelections = {};
    //         mergedArray.forEach(player => {
    //             initialSelections[player.id] = userTeamPlayerIds.has(player?.id);
    //         });

    //         setAllPlayersByMatchData(mergedArray);
    //         setPlayerSelections(initialSelections);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }


    const fetchPlayersByMatch = () => {
        https.get(`/TournamentTeamPlayerApi/GetPlayersByMatch?matchId=${data.id}`).then(async (res) => {
            let allPlayersData = await res?.data?.data;
            setTeam1Id(allPlayersData?.team1Players[0]?.tournamentTeamId)
            setTeam2Id(allPlayersData?.team2Players[0]?.tournamentTeamId)

            let mergedArray = allPlayersData?.team1Players?.concat(allPlayersData?.team2Players);
            setAllPlayersByMatchData(mergedArray);

            // new code 
            const initialSelections = {};
            mergedArray?.forEach(player => {
                initialSelections[player?.id] = false; // Assuming 'id' is the unique identifier for a player
            });
            setPlayerSelections(initialSelections);
        }).catch((e) => console.log(e))
    }

    // useEffect(() => {
    //     fetchPlayersByMatch()
    // }, [])



    const toggleActiveTabOnePlayerSelection = (playerId, item, activeTab) => {
        const isCurrentlySelected = playerSelections[playerId];

        // If the player is not currently selected, check if the limit is exceeded
        if (!isCurrentlySelected) {
            // Count the number of selected players in the current active tab
            const countSelectedInTab = totalWicketKeepers?.filter(player => player?.activeTab === activeTab).length;

            if (countSelectedInTab >= 3) {
                toast.error("You can't select more than 3 Players");
                return; // Exit the function without making any changes
            }
            if (finalTeamPlayers.length >= 11) {
                toast.error("Only 11 Players!")
                return
            }
        }

        if (isCurrentlySelected) {
            // If deselecting, update budget and remove player from selectedPlayers
            setTotalBudget(totalBudget + item?.playerCost);
            setTotalWicketKeepers(prev => prev.filter(player => player?.id !== playerId));
            if (item?.tournamentTeamId == team1Id) {
                setTeam1Count(team1Count - 1)
            }
            if (item?.tournamentTeamId == team2Id) {
                setTeam2Count(team2Count - 1)
            }
        } else {
            // If selecting, update budget and add player to selectedPlayers
            if (totalBudget - item?.playerCost >= 0) {
                setTotalBudget(totalBudget - item?.playerCost);
                setTotalWicketKeepers(prev => [...prev, { ...item, activeTab }]);
                if (item?.tournamentTeamId == team1Id) {
                    setTeam1Count(team1Count + 1)
                }
                if (item?.tournamentTeamId == team2Id) {
                    setTeam2Count(team2Count + 1)
                }
            } else {
                toast.error("Budget Finished")
                return; // Exit the function without making any changes to selections
            }
        }

        // Update the player selections state
        setPlayerSelections(prev => ({
            ...prev,
            [playerId]: !isCurrentlySelected
        }));
    };

    const toggleActiveTabTwoPlayerSelection = (playerId, item, activeTab) => {
        const isCurrentlySelected = playerSelections[playerId];

        // If the player is not currently selected, check if the limit is exceeded
        if (!isCurrentlySelected) {
            // Count the number of selected players in the current active tab
            const countSelectedInTab = totalBatsman?.filter(player => player?.activeTab === activeTab).length;

            if (countSelectedInTab >= 6) {
                toast.error("You can't select more than 6 Players");
                return; // Exit the function without making any changes
            }
        }

        if (isCurrentlySelected) {
            // If deselecting, update budget and remove player from selectedPlayers
            setTotalBudget(totalBudget + item?.playerCost);
            setTotalBatsman(prev => prev.filter(player => player?.id !== playerId));
            if (item?.tournamentTeamId == team1Id) {
                setTeam1Count(team1Count - 1)
            }
            if (item?.tournamentTeamId == team2Id) {
                setTeam2Count(team2Count - 1)
            }
        } else {
            // If selecting, update budget and add player to selectedPlayers
            if (totalBudget - item?.playerCost >= 0) {
                setTotalBudget(totalBudget - item?.playerCost);
                setTotalBatsman(prev => [...prev, { ...item, activeTab }]);
                if (item?.tournamentTeamId == team1Id) {
                    setTeam1Count(team1Count + 1)
                }
                if (item?.tournamentTeamId == team2Id) {
                    setTeam2Count(team2Count + 1)
                }
            } else {
                toast.error("Budget Finished")
                return; // Exit the function without making any changes to selections
            }
        }

        // Update the player selections state
        setPlayerSelections(prev => ({
            ...prev,
            [playerId]: !isCurrentlySelected
        }));
    };


    const toggleActiveTabThreePlayerSelection = (playerId, item, activeTab) => {
        const isCurrentlySelected = playerSelections[playerId];

        // If the player is not currently selected, check if the limit is exceeded
        if (!isCurrentlySelected) {
            // Count the number of selected players in the current active tab
            const countSelectedInTab = hitter?.filter(player => player?.activeTab === activeTab)?.length;

            if (countSelectedInTab >= 4) {
                toast.error("You can't select more than 4 Players");
                return; // Exit the function without making any changes
            }
            if (finalTeamPlayers?.length >= 11) {
                toast.error("Only 11 Players!")
                return
            }
        }

        if (isCurrentlySelected) {
            // If deselecting, update budget and remove player from selectedPlayers
            setTotalBudget(totalBudget + item?.playerCost);
            setHitter(prev => prev.filter(player => player?.id !== playerId));
            if (item?.tournamentTeamId == team1Id) {
                setTeam1Count(team1Count - 1)
            }
            if (item?.tournamentTeamId == team2Id) {
                setTeam2Count(team2Count - 1)
            }
        } else {
            // If selecting, update budget and add player to selectedPlayers
            if (totalBudget - item?.playerCost >= 0) {
                setTotalBudget(totalBudget - item?.playerCost);
                setHitter(prev => [...prev, { ...item, activeTab }]);
                if (item?.tournamentTeamId == team1Id) {
                    setTeam1Count(team1Count + 1)
                }
                if (item?.tournamentTeamId == team2Id) {
                    setTeam2Count(team2Count + 1)
                }
            } else {
                toast.error("Budget Finished")
                return; // Exit the function without making any changes to selections
            }
        }

        // Update the player selections state
        setPlayerSelections(prev => ({
            ...prev,
            [playerId]: !isCurrentlySelected
        }));
    };



    const toggleActiveTabFourPlayerSelection = (playerId, item, activeTab) => {
        const isCurrentlySelected = playerSelections[playerId];

        // If the player is not currently selected, check if the limit is exceeded
        if (!isCurrentlySelected) {
            // Count the number of selected players in the current active tab
            const countSelectedInTab = baller?.filter(player => player?.activeTab === activeTab)?.length;

            if (countSelectedInTab >= 4) {
                toast.error("You can't select more than 6 Players");
                return; // Exit the function without making any changes
            }
            if (finalTeamPlayers.length >= 11) {
                toast.error("Only 11 Players!")
                return
            }
        }

        if (isCurrentlySelected) {
            // If deselecting, update budget and remove player from selectedPlayers
            setTotalBudget(totalBudget + item?.playerCost);
            setBaller(prev => prev.filter(player => player?.id !== playerId));
            if (item?.tournamentTeamId == team1Id) {
                setTeam1Count(team1Count - 1)
            }
            if (item?.tournamentTeamId == team2Id) {
                setTeam2Count(team2Count - 1)
            }
        } else {
            // If selecting, update budget and add player to selectedPlayers
            if (totalBudget - item?.playerCost >= 0) {
                setTotalBudget(totalBudget - item?.playerCost);
                setBaller(prev => [...prev, { ...item, activeTab }]);
                if (item?.tournamentTeamId == team1Id) {
                    setTeam1Count(team1Count + 1)
                }
                if (item?.tournamentTeamId == team2Id) {
                    setTeam2Count(team2Count + 1)
                }
            } else {
                toast.error("Budget Finished")
                return; // Exit the function without making any changes to selections
            }
        }

        // Update the player selections state
        setPlayerSelections(prev => ({
            ...prev,
            [playerId]: !isCurrentlySelected
        }));
    };







    const selectCaptain = (playerId) => {
        if (playerId !== selectedViceCaptain) {
            setSelectedCaptain(playerId);
        } else {
            toast.error("Same player cannot be both Captain and Vice Captain");
        }
    };

    const selectViceCaptain = (playerId) => {
        if (playerId !== selectedCaptain) {
            setSelectedViceCaptain(playerId);
        } else {
            toast.error("Same player cannot be both Captain and Vice Captain");
        }
    };
    const handleCreateTeam = () => {
        setShowModal1(true)

    }




    const handleAllSelectedTeam = () => {
        // Count players by team and role
        const teamCounts = {};
        let bowlersCount = 0, batsmenCount = 0, keepersCount = 0;
        let team1Count = 0, team2Count = 0;
        finalTeamPlayers.forEach(player => {
            // Count players from each team
            teamCounts[player?.teamName] = (teamCounts[player?.teamName] || 0) + 1;

            // Count players by role
            if (player?.playerRole === 1) bowlersCount++;
            if (player?.playerRole === 0) batsmenCount++;
            if (player?.playerRole === 3) keepersCount++;

            console.log(player.id)
            // if (player.teamId === <Team1_ID>) { // Replace <Team1_ID> with the actual identifier for Team 1
            //     team1Count++;
            // } else if (player.teamId === <Team2_ID>) { // Replace <Team2_ID> with the actual identifier for Team 2
            //     team2Count++;
            // }
        });

        // Check the team composition
        const maxPlayersFromOneTeam = Object.values(teamCounts).every(count => count <= 7);
        const hasMinimumBowlers = bowlersCount >= 2;
        const hasMinimumBatsmen = batsmenCount >= 3;
        const hasMinimumKeepers = keepersCount >= 1;

        // Alert the user if conditions are not met
        if (!maxPlayersFromOneTeam) {
            toast.error("You can't have more than 7 players from one team.");
            return;
        }
        if (!hasMinimumBowlers) {
            toast.error("You must select at least two bowlers.");
            return;
        }
        if (!hasMinimumBatsmen) {
            toast.error("You must select at least three batsmen.");
            return;
        }
        if (!hasMinimumKeepers) {
            toast.error("You must select at least one wicket keeper.");
            return;
        }
        setIsCaptainVoiceCaptionSelection(true);
    };
    const handleCreationTeam = () => {
        let matchId = data?.id
        let team1Id = data?.tournamentTeam1Id
        let team2Id = data?.tournamentTeam2Id
        let finalist = finalTeamPlayers?.map(player => ({
            id: player?.id,
            playerId: player?.playerId,
            tournamentTeamId: player?.tournamentTeamId,
            playerRole: player?.playerRole,
            playerCost: player?.playerCost,
            isCaptain: player?.id === selectedCaptain, // Assuming selectedCaptain is the ID of the captain
            isViceCaptain: player?.id === selectedViceCaptain // Assuming selectedViceCaptain is the ID of the vice captain
        }));

        https.post(`/ContestApi/GenerateUserTeamForMatch?matchId=${matchId}&team1Id${team1Id}&team2Id=${team2Id}&teamName=${teamName}`, finalist).then((res) => {
            CustomAlerts("Team Created Successfully", NotificationTypes.SUCCESS)
            setTimeout(() => {
                // window.location.reload()
                setShowModal1(false)
                setPrevActiveTab(3)
            }, 1500);

        }).catch((err) => {
            console.log({ err })
        })


    };

    function categorizePlayers(players) {
        return {
            keepers: players.filter(player => player?.playerRole === 3),
            hitters: players.filter(player => player?.playerRole === 2),
            batsmen: players.filter(player => player?.playerRole === 0),
            ballers: players.filter(player => player?.playerRole === 1)
        };
    }

    // const getUserTeam = async () => {
    //     let userTeamId = 9200;
    //     try {
    //         const response = await https.get(`/ContestApi/GetUserTeamById?userTeamId=${userTeamId}`);
    //         let userTeam = response?.data?.data;
    //         console.log("userTeam", userTeam)
    //         setUpdatedTeamId(userTeam[0].userTeamId)
    //         if (userTeam && userTeam.length > 0) {
    //             // If there is existing team data, it means we're updating the team
    //             setIsUpdatingTeam(false);
    //             console.log("Updating existing team");
    //             let totalCost = userTeam.reduce((sum, player) => sum + player.playerCost, 0);
    //             console.log(totalCost)
    //             const categorizedPlayers = categorizePlayers(userTeam);
    //             setTotalWicketKeepers(categorizedPlayers.keepers)
    //             setTotalBatsman(categorizedPlayers.batsmen)
    //             setHitter(categorizedPlayers.hitters)
    //             setBaller(categorizedPlayers.ballers)
    //             setTotalBudget(100 - totalCost)

    //             setFinalTeamPlayers(userTeam)
    //             setAllPlayersByMatchData(userTeam)
    //         } else {
    //             // No existing team data, so it's a new team creation
    //             setIsUpdatingTeam(false);
    //             console.log("Creating new team");
    //         }

    //         return userTeam.map(player => player.id); // Assuming 'id' is the unique identifier for a player
    //     } catch (err) {
    //         console.error({ err });
    //         return [];
    //     }

    // }


    useEffect(() => {

        const combinedTeam = [
            ...totalWicketKeepers,
            ...totalBatsman,
            ...hitter,
            ...baller
        ];

        setFinalTeamPlayers(combinedTeam);
    }, [totalWicketKeepers, totalBatsman, hitter, baller]);


    const fetchUserTeamByMatchId = async () => {
        https.get(`/ContestApi/GetUserTeamsForMatchWithPoints?matchId=${data?.id}`).then(async (res) => {
            let userTeam = await res?.data?.data;
            // if (userTeam?.length > 0) {
            //     fetchPlayersByMatchWithUserTeam()
            //     setCreatedUserTeam(userTeam)
            // }
            // else {
            fetchPlayersByMatch()
            // }

        }).catch((e) => {
            console.log(e)
        })

    }

    useEffect(() => {
        fetchUserTeamByMatchId()
    }, [])




    return (
        <>

            <div className='w-100 d-flex justify-content-around align-items-center' style={{ height: 70 }}>
                <div><img src={data?.team1Logo} height={60} width={60} /><span className='fw-bold text-white mx-2'>{team1Count}</span></div>
                <div><img src={data?.team2Logo} height={60} width={60} /><span className='fw-bold text-white mx-2'>{team2Count}</span></div>
            </div>

            {showModal1 && (
                <CreateTeamModal
                    showModal={showModal1}
                    setShowModal={setShowModal1}
                    modalAnimation={modalAnimation}
                    // activeTab={activeTab}
                    // setActiveTab={setActiveTab}
                    handleCloseModal={handleCloseModal1}
                    handleDepositAmount={handleCreationTeam}
                    getAnimationStyle={getAnimationStyle}
                    teamName={teamName}
                    setTeamName={setTeamName}

                />
            )}
            {isCaptainVoiceCaptionSelection ?
                <>
                    <table className="table table-bordered" id=''>
                        <thead style={{ backgroundColor: "transparent" }}>
                            <tr>
                                <td className='text-center fw-bold' colSpan={selectCaptain && selectViceCaptain ? 4 : 5}>Select Your Captain & Voice Captain</td>
                                {
                                    (selectedCaptain && selectedViceCaptain) && <td className='text-center fw-bold' ><button className='btn btn-success' onClick={handleCreateTeam}>Continue</button></td>
                                }


                            </tr>
                        </thead>

                        <tbody id='table-body' >
                            {
                                finalTeamPlayers?.map((item) => {
                                    return (
                                        <tr className='select-team-caption-voice-caption'>
                                            <td scope="row" width={"20%"}><img src={item?.playerImage ? item?.playerImage : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100 }} /></td>
                                            <td>{item?.playerName}</td>
                                            <td></td>
                                            <td>{item?.playerCost}</td>
                                            <td>
                                                <div className='d-flex'>
                                                    <div
                                                        className={`mx-2 btn ${selectedCaptain === item?.id ? 'btn-primary' : 'btn-outline-primary'}`}
                                                        onClick={() => selectCaptain(item?.id)}
                                                    >
                                                        C
                                                    </div>

                                                    <div
                                                        className={`mx-2 btn ${selectedViceCaptain === item?.id ? 'btn-primary' : 'btn-outline-primary'}`}
                                                        onClick={() => selectViceCaptain(item?.id)}

                                                    >
                                                        VC
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </>
                : <> <div id='select-team-container'>
                    <div className={`tabs tabs-1 d-flex justify-content-center align-items-center flex-column`}>
                        <div className={`tabs-number ${activeTab == 1 ? 'active-tab' : ''}`} onClick={() => handleTabClick(1)}>
                            <img src={gloves_unactive} height={30} width={30} />
                        </div>
                        <h6 className='length-text'>{totalWicketKeepers.length} / 3</h6>
                    </div>

                    <div className={`tabs tabs-2 d-flex justify-content-center align-items-center flex-column`}>
                        <div className={`tabs-number ${activeTab == 2 ? 'active-tab' : ''}`} onClick={() => handleTabClick(2)} >
                            <img src={batsman_unactive} height={30} width={30} />
                        </div>
                        <h6 className='length-text'>{totalBatsman.length} / 6</h6>
                    </div>

                    <div className={`tabs tabs-3  d-flex justify-content-center align-items-center flex-column`}>
                        <div className={`tabs-number ${activeTab == 3 ? 'active-tab' : ''}`} onClick={() => handleTabClick(3)}>
                            <img src={hitter_unactive} height={30} width={30} />
                        </div>
                        <h6 className='length-text'>{hitter.length} / 4</h6>
                    </div>
                    <div className={`tabs tabs-4  d-flex justify-content-center align-items-center flex-column`}>
                        <div className={`tabs-number ${activeTab == 4 ? 'active-tab' : ''}`} onClick={() => handleTabClick(4)}>
                            <img src={baller_unactive} height={30} width={30} />
                        </div>
                        <h6 className='length-text'>{baller.length} / 6</h6>
                    </div>
                </div>
                    {/* show */}
                    <div id='select-teams-table'>


                        <table className="table table-bordered">
                            <thead style={{ backgroundColor: "transparent" }}>
                                <tr>
                                    <th scope="col" >{finalTeamPlayers?.length === 11 && (
                                        <button className='btn btn-primary mx-3'
                                            style={{ borderRadius: "10px", marginRight: 0 }}
                                            onClick={handleAllSelectedTeam} >
                                            Create Team
                                        </button>
                                    )}</th>
                                    <th scope="col" className='th-text'>Selected By</th>
                                    <th scope="col" className='th-text'>Budget : {totalBudget}</th>
                                    <th scope="col" className='th-text'>Cost</th>

                                </tr>
                            </thead>
                            {
                                loading ?
                                    <Loader /> :
                                    <tbody id='table-body' >
                                        {
                                            allPlayersByMatchData?.length == 0 &&
                                            <tr
                                            ><td colSpan={5} className='text-center text-black'> No Team Players Found Yet</td></tr>
                                        }
                                        {
                                            activeTab == 1 ?
                                                allPlayersByMatchData?.filter(i => i.playerRole == 3)?.sort((a, b) => b.playerCost - a.playerCost)?.map((item, index) => {
                                                    return (

                                                        <tr>
                                                            <td scope="row" width={"20%"}>
                                                                {item.isPlaying == true ?
                                                                    <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Icon_green_lamp_on.svg/480px-Icon_green_lamp_on.svg.png' height={15} style={{ zIndex: 10000, borderRadius: 100, paddingRight: 30, marginBottom: -11 }} />
                                                                    :
                                                                    null}
                                                                <img src={item?.playerImage ? item?.playerImage : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100, border: '2px solid rgb(50 54 179 / 33%)' }} />

                                                            </td>
                                                            <td>{item?.playerName} <br /> {item.isPlaying == true ? <span style={{ color: '#02bb02' }}><b>Playing...</b></span> : null} </td>
                                                            <td>{item?.teamName}</td>
                                                            <td>{item?.playerCost}</td>
                                                            <td>
                                                                {/* <img
                                                                    src={playerSelections[item.id] ? crossIcon : plusIcon}
                                                                    height={25}
                                                                    width={25}
                                                                    onClick={() => {
                                                                        if (totalBudget < 0) {
                                                                            toast.error("Budget Finished!")
                                                                        }

                                                                        else {
                                                                            toggleActiveTabOnePlayerSelection(item.id, item, activeTab)
                                                                        }
                                                                    }
                                                                    }
                                                                /> */}

                                                                <i
                                                                    className={`fa-solid ${playerSelections[item?.id] ? "fa-circle-xmark" : "fa-circle-plus"} fa-xl`} style={{ color: playerSelections[item?.id] ? "#e11c0e" : "#3cbd05" }} onClick={() => {


                                                                        toggleActiveTabOnePlayerSelection(item?.id, item, activeTab)

                                                                    }}
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : activeTab == 2 ?
                                                    allPlayersByMatchData?.filter(i => i?.playerRole == 0)?.sort((a, b) => b?.playerCost - a?.playerCost)?.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td scope="row" width={"20%"}>
                                                                    {item.isPlaying == true ?
                                                                        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Icon_green_lamp_on.svg/480px-Icon_green_lamp_on.svg.png' height={15} style={{ zIndex: 10000, borderRadius: 100, paddingRight: 30, marginBottom: -11 }} />
                                                                        :
                                                                        null}
                                                                    <img src={item?.playerImage ? item?.playerImage : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100, border: '2px solid rgb(50 54 179 / 33%)' }} />

                                                                </td>
                                                                <td>{item?.playerName} <br /> {item.isPlaying == true ? <span style={{ color: '#02bb02' }}><b>Playing...</b></span> : null} </td>
                                                                <td>{item?.teamName}</td>
                                                                <td>{item?.playerCost}</td>
                                                                <td>
                                                                    {/* <img
                                                                    src={playerSelections[item.id] ? crossIcon : plusIcon}
                                                                    height={25}
                                                                    width={25}
                                                                    onClick={() => {
                                                                        if (totalBudget < 0) {
                                                                            toast.error("Budget Finished!")
                                                                        }

                                                                        else {
                                                                            toggleActiveTabTwoPlayerSelection(item.id, item, activeTab)
                                                                        }
                                                                    }
                                                                    }
                                                                /> */}
                                                                    <i
                                                                        className={`fa-solid ${playerSelections[item?.id] ? "fa-circle-xmark" : "fa-circle-plus"} fa-xl`} style={{ color: playerSelections[item?.id] ? "#e11c0e" : "#3cbd05" }} onClick={() => {


                                                                            toggleActiveTabTwoPlayerSelection(item?.id, item, activeTab)

                                                                        }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    activeTab == 3 ?
                                                        allPlayersByMatchData?.filter(i => i?.playerRole == 2)?.sort((a, b) => b?.playerCost - a?.playerCost)?.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td scope="row" width={"20%"}>
                                                                        {item.isPlaying == true ?
                                                                            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Icon_green_lamp_on.svg/480px-Icon_green_lamp_on.svg.png' height={15} style={{ zIndex: 10000, borderRadius: 100, paddingRight: 30, marginBottom: -11 }} />
                                                                            :
                                                                            null}
                                                                        <img src={item?.playerImage ? item?.playerImage : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100, border: '2px solid rgb(50 54 179 / 33%)' }} />

                                                                    </td>
                                                                    <td>{item?.playerName} <br /> {item.isPlaying == true ? <span style={{ color: '#02bb02' }}><b>Playing...</b></span> : null} </td>
                                                                    <td>{item?.teamName}</td>
                                                                    <td>{item?.playerCost}</td>
                                                                    <td>
                                                                        {/* <img
                                                                        src={playerSelections[item.id] ? crossIcon : plusIcon}
                                                                        height={25}
                                                                        width={25}
                                                                        onClick={() => {
                                                                            if (totalBudget < 0) {
                                                                                toast.error("Budget Finished!")
                                                                            }

                                                                            else {
                                                                                toggleActiveTabThreePlayerSelection(item.id, item, activeTab)

                                                                            }
                                                                        }
                                                                        }
                                                                    /> */}
                                                                        <i
                                                                            className={`fa-solid ${playerSelections[item?.id] ? "fa-circle-xmark" : "fa-circle-plus"} fa-xl`} style={{ color: playerSelections[item?.id] ? "#e11c0e" : "#3cbd05" }} onClick={() => {


                                                                                toggleActiveTabThreePlayerSelection(item?.id, item, activeTab)

                                                                            }}
                                                                        ></i>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        activeTab == 4 ?
                                                            allPlayersByMatchData?.filter(i => i?.playerRole == 1)?.sort((a, b) => b?.playerCost - a?.playerCost)?.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td scope="row" width={"20%"}>
                                                                            {item.isPlaying == true ?
                                                                                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Icon_green_lamp_on.svg/480px-Icon_green_lamp_on.svg.png' height={15} style={{ zIndex: 10000, borderRadius: 100, paddingRight: 30, marginBottom: -11 }} />
                                                                                :
                                                                                null}
                                                                            <img src={item?.playerImage ? item?.playerImage : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100, border: '2px solid rgb(50 54 179 / 33%)' }} />

                                                                        </td>
                                                                        <td>{item?.playerName} <br /> {item.isPlaying == true ? <span style={{ color: '#02bb02' }}><b>Playing...</b></span> : null} </td>
                                                                        <td>{item?.teamName}</td>
                                                                        <td>{item?.playerCost}</td>
                                                                        <td>
                                                                            <i
                                                                                className={`fa-solid ${playerSelections[item?.id] ? "fa-circle-xmark" : "fa-circle-plus"} fa-xl`} style={{ color: playerSelections[item?.id] ? "#e11c0e" : "#3cbd05" }} onClick={() => {
                                                                                    toggleActiveTabFourPlayerSelection(item?.id, item, activeTab)

                                                                                }}
                                                                            ></i>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : <></>
                                        }

                                    </tbody>
                            }
                        </table>
                    </div></>}

        </>

    )
}

export default SelectTeamTab
