import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PrivacyPolicy = () => {
  const pdfUrl = "https://dev-v11.cloudistic.net/UserApi/PrivacyPolicy";

  return (
    <div>
        <h3 style={{ textAlign:'center', color:'white'}}>Virutal 11 Privacy policy</h3>
    <div style={{ height: '80vh',width:'60vw', marginLeft:'20%' }}> 
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer fileUrl={pdfUrl} />
      </Worker>
    </div>
    </div>
  );
};

export default PrivacyPolicy;