import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../redux/authSlice';
import { CustomAlerts, NotificationTypes } from '../../components/CustomAlerts/CustomAlerts';
import https from '../../utils/axiosApi';
import axios from 'axios';
import backgroundImage from "../../assets/images/bg-for-login.png"
const Signup = () => {
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [password, setPassword] = useState("")
    const [operator, setOperator] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleRegister = async () => {
        let body = {
            name: name,
            password: password,
            phoneNumber: phoneNumber,
            operator: operator
        }
        if (!name) {
            CustomAlerts("Kindly Enter Name", NotificationTypes.ERROR)
            return
        }
        if (!phoneNumber) {
            CustomAlerts("Kindly Enter Phone Number", NotificationTypes.ERROR)
            return
        }
        if (!password) {
            CustomAlerts("Kindly Enter Password", NotificationTypes.ERROR)
            return
        }
        if (!operator) {
            CustomAlerts("Kindly Select Operator", NotificationTypes.ERROR)
            return
        }

        axios.post("https://dev-v11.cloudistic.net/UserApi/RegisterUser", body).then((res) => {
            CustomAlerts("Verify your OTP", NotificationTypes.SUCCESS)
            setTimeout(() => {
                navigate("/verification", { state: { phoneNumber: phoneNumber } })
            }, 1500);
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleOperatorChange = (event) => {
        setOperator(event.target.value);
    };


    

    return (
        <div className='w-100 d-flex justify-content-center align-items-center'>
            <div className='form-login d-flex justify-content-center align-items-center flex-column'>

                <div className='input-fields'>
                    <h1 className='text-white text-center fw-bold'>Sign Up</h1>
                </div>
                <div className='input-fields'>
                    <input type="text" className='login-input' placeholder='Enter Your Name' value={name} onChange={(e) => {
                        if (e.target.value.length === 0 || e.target.value[0] !== ' ') {
                            setName(e.target.value);
                        }
                    }} />
                </div>
                <div className='input-fields d-flex' style={{ position: "relative" }}>

                    <input type="text" className='login-input' placeholder='0302XXXXXXX' value={phoneNumber} onChange={(e) => {
                        const input = e.target.value;
                        const filteredInput = input.replace(/[^0-9+]/g, '');
                        const maxLength = filteredInput.startsWith('+') ? 13 : 11;
                        if (filteredInput.length <= maxLength) {
                            setPhoneNumber(filteredInput);
                        }
                    }} />
                    <select className="form-select form-select-sm" id='operator-signup' aria-label=".form-select-sm example"
                        onChange={handleOperatorChange}
                    >
                        <option selected>Select Operator</option>
                        <option value="zong">Zong</option>
                        <option value="jazz">Jazz</option>
                        <option value="ufone">Ufone</option>
                        <option value="warid">Warid</option>
                        <option value="telenor">Telenor</option>
                    </select>
                </div>
                <div className='input-fields'>
                    <input type="text" className='login-input' placeholder='Enter Your Password' value={password} onChange={(e) => {
                        if (e.target.value.length <= 35) {
                            setPassword(e.target.value);
                        }
                    }} />
                </div>
                <div className='input-fields'>
                    <button className='btn btn-outline-primary' onClick={handleRegister}>Sign Up Now</button>
                </div>
                <div className='input-fields'>
                    <h6 className='text-white'> Already have an account ? <span className='fw-bold text-warning cursor-pointer' onClick={() => { navigate("/login") }}>Sign In</span></h6>
                </div>
            </div>
        </div>
    )
}

export default Signup
