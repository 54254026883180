import React, { useEffect, useState } from 'react'
import "./headerLatest.css"
import logo from "../../assets/images/virtual-logo.png"
import accountLogo from "../../assets/images/account.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DepositModal from '../DepositModal/DepositModal'
import { logout } from '../../redux/authSlice'
import { CustomAlerts, NotificationTypes } from '../CustomAlerts/CustomAlerts'
import { useDispatch } from 'react-redux'
import https from '../../utils/axiosApi'
const Header2 = () => {
    const [clicked, setClicked] = useState(false)
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)
    const [modalAnimation, setModalAnimation] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState('bankTransfer');
    const [userData, setUserData] = useState(null);
    const [isLogged, setIsLogged] = useState(false)
    const [amount, setAmount] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    const handleDepositAmount = () => {
        handleOpenModal();
    };

    const handleOpenModal = () => {
        setShowModal(true);
        setModalAnimation('modal-custom-enter-active');
        setIsDropDownOpen(false)
        if(clicked){
            setClicked(false)
        }
    };

    const handleCloseModal = () => {
        setModalAnimation('modal-custom-exit-active');
        setTimeout(() => {
            setShowModal(false);
        }, 300); // Should match the duration of the CSS transition
    };
    const getAnimationStyle = (tabName) => {
        return activeTab === tabName ? 'fadeIn 0.5s' : 'fadeOut 0.5s';
    };

    const handleLogout = () => {
        setIsDropDownOpen(false)
        dispatch(logout())
        CustomAlerts("Successfully Logout!", NotificationTypes.SUCCESS)
        setTimeout(() => {
            navigate("/login")
        }, 1500);
    };

    useEffect(() => {
        let token = localStorage.getItem("token")
        if (!token) {
            setIsLogged(false)
            setIsDropDownOpen(false)
        }
        else {
            setIsLogged(true)
        }

        setIsDropDownOpen(false)
        setClicked(false)
    }, [location.pathname])


    useEffect(() => {
        // Function to fetch user data
        const fetchData = async () => {
          try {
            const res = await https.get("/UserApi/GetUserById");
            const userData = res?.data?.data;
            setUserData(userData);
            const userCurrentBalance = userData?.balance;
            localStorage.setItem("userCurrentBalance", JSON.parse(userCurrentBalance));
          } catch (err) {
            console.log(err);
          }
        };
    
        // Call fetchData immediately when the component mounts
        fetchData();
    
        // Schedule fetchData to run every 30 seconds
        const intervalId = setInterval(fetchData, 30000);
    
        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
      }, []);

      let balance = JSON.parse(localStorage.getItem("user"))?.balance

    return (
        <>
            {showModal && (
                <DepositModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    modalAnimation={modalAnimation}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleCloseModal={handleCloseModal}
                    handleDepositAmount={handleDepositAmount}
                    getAnimationStyle={getAnimationStyle}
                    amount={amount}
                    setAmount={setAmount}

                />
            )}

            <nav>
                <Link to="/"><img src={logo} height={40} className='img-main-lgoo' /></Link>

                <div >
                    <ul id='latest-navbar-lists' className={clicked ? `#latest-navbar-lists active-nav` : '#latest-navbar-lists'}>
                    <li><Link to='/points-system' className={location.pathname === '/points-system' ? 'active-bar' : ''}>Point System</Link></li>
                        {isLogged && <><li><Link to='/' className={location.pathname === '/' ? 'active-bar' : ''}>Matches</Link></li>
                            
                            <li onClick={() => { setIsDropDownOpen(!isDropDownOpen) }}
                            >
                                <Link to='#' className={location.pathname === '/profile' ? 'active-bar' : ''}>Profile<i class="fa-solid fa-chevron-down mx-1"></i></Link>
                            </li></>}
                        {
                            isDropDownOpen && <>
                                <div className='drop-down-info'>
                                    <span className='mx-2 text-white '  style={{ cursor: "pointer" }} onClick={() => {
                                        navigate("/profile")
                                    }}> <i class="fa-regular fa-user mx-1 text-white"></i> View Profile</span>
                                    <span className='mx-2 text-white' style={{ cursor: "pointer" }}><i class="fa-solid fa-wallet mx-1 text-white"></i>{userData?.balance ? userData?.balance : balance} PKR </span>
                                    <span className='mx-2 text-white' style={{ cursor: "pointer" }} onClick={handleDepositAmount}><i class="fa-solid fa-money-bill-transfer mx-1 text-white"></i>Deposit Amount </span>
                                    <span className='mx-2 text-white' style={{ cursor: "pointer" }} onClick={()=>{navigate("/transaction-history")}}><i class="fa-solid fa-history mx-1 text-white"></i>Transaction History </span>
                                    <span className='mx-2 text-white' style={{ cursor: "pointer" }} onClick={handleLogout}><i class="fa-solid fa-right-from-bracket mx-1 text-white"></i>Logout</span>
                                </div>
                            </>
                        }

                        <li
                            style={{ background: "#653aab", borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "10px 60px" }} className='btn-downlooad'><a href='https://tinyurl.com/v11apk' target='_blank' style={{ fontSize: 14 }}>Download APK</a></li>
                    </ul>
                </div>



                <div id='mob-nav'>
                    <i id='bar' className={`fas ${clicked ? 'fa-times' : 'fa-bars'}`} onClick={() => { setClicked(!clicked) }} />

                </div>
            </nav>
        </>
    )
}

export default Header2