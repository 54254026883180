import React, { useState, useEffect } from 'react';
import moment from 'moment';
// Custom hook for setInterval with cleanup
function useInterval(callback, delay) {
    const savedCallback = React.useRef();

    // Remember the latest callback if it changes.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const CounterDown = ({ targetDateTime }) => {
    const [countdownValues, setCountdownValues] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    // Use the custom useInterval hook to update the countdown every second
    useInterval(() => {
        const targetMoment = moment(targetDateTime);
        const currentDateTime = moment();
        const differenceInMillis = targetMoment.diff(currentDateTime);

        if (differenceInMillis > 0) {
            const duration = moment.duration(differenceInMillis);

            setCountdownValues({
                days: Math.floor(duration.asDays()),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds(),
            });
        } else {
            // If the targetDateTime is in the past, set countdown values to 0
            setCountdownValues({
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            });
        }
    }, 1000);

    return `${countdownValues.days===0? '' : countdownValues.days +' days,'} ${countdownValues.hours}Hr ${countdownValues.minutes}m ${countdownValues.seconds}s`
};

export default CounterDown;