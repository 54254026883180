import axios from 'axios';


let baseUrl  = "https://dev-v11.cloudistic.net"
// Create an instance of Axios with your base URL and configuration
const https = axios.create({
    baseURL: baseUrl,
});

// Add a request interceptor to add headers or perform any other request-related tasks
https.interceptors.request.use(
    async (config) => {
        try {
            // Retrieve the token from local storage
            const token = localStorage.getItem('token');

            // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcmltYXJ5c2lkIjoiNTk3IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbW9iaWxlcGhvbmUiOiIwMzE4NDI2MTE5MCIsImF1ZCI6InYxMS1hdWRpZW5jZSIsImlzQWRtaW4iOiJGYWxzZSIsImlzcyI6InYxMS1pc3N1ZXIiLCJuYmYiOjE2OTk2OTQ4NzcsImV4cCI6MTY5OTcxNjQ3NywiaWF0IjoxNjk5Njk0ODc3fQ.MoQb_5euHDOo4vejWgdgZvrla1uzxRRhwZ9aeTUNK7k"
// 
            // Set the token as the Authorization header
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle responses or perform any other response-related tasks
https.interceptors.response.use(
    (response) => {
        // You can handle successful responses here
        return response;
    },
    (error) => {
        // Handle error responses here
        if (error.response) {
            // The request was made and the server responded with a non-2xx status code
        } else if (error.request) {
            // The request was made but no response was received
        } else {
            // Something happened in setting up the request that triggered an error
        }

        // You can handle errors here or re-throw the error
        return Promise.reject(error.response || error);
    }
);

export default https;
