import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import https from '../../utils/axiosApi';
import Loader from '../../components/Common/Loader';
import defaultPlayerImage from "../../assets/images/cricket-player.png"
const ViewTeam = () => {
    const location = useLocation()
    const teamId = location.state?.teamId;
    const teamName = location.state?.teamName;
    const matchId = location.state?.matchId;
    const [userTeamData, setUserTeamData] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")

    const fetchUserTeamData = async () => {
        setLoading(true)
        https.get(`/ContestApi/GetPointsForUserTeam?userTeamId=${teamId}`).then(async (response) => {
            console.log(response?.data?.isSuccess)
            let isSuccess = await response?.data?.isSuccess
            if (isSuccess) {
                let allPlayersData = await response?.data?.data?.userTeamPlayerModels
                setUserTeamData(allPlayersData)
                setLoading(false)
            }
            else {
                let userMessage =  await response?.data?.message 
                setMessage(userMessage)
                setLoading(false)
            }


        }).catch((err) => {
            setLoading(false)
        })

    }
    useEffect(() => {
        fetchUserTeamData()

    }, [])

    return (
        <div className='my-5 main-info-container w-100 d-flex justify-content-center align-items-center flex-column'>
            <div id='match-updates-container'>
                <h3>{teamName}</h3>
            </div>

            <div className='main-container-matches my-3' style={{ width: "80%" }}>
                <table className="table table-bordered">
                    {
                        loading ?
                            <Loader /> :
                            <tbody id='table-body' >
                                {/* {
                                            userTeamData?.length == 0 &&
                                            <tr
                                            ><td colSpan={5} className='text-center text-black'> No Team Players Found Yet</td></tr>
                                        } */}
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th>Points</th>
                                </tr>
                                {
                                    userTeamData?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td scope="row" width={"20%"}><img src={item?.playerPicture ? item?.playerPicture : defaultPlayerImage} height={50} width={50} style={{ borderRadius: 100 }} /></td>
                                                <td>{item?.playerName} {item?.isCaptain && "(C)"} {item?.isViceCaptain && "(VC)"}</td>
                                                <td>

                                                    {
                                                        item?.playerRole == 3 ? "Wicket Keeper" :
                                                            item?.playerRole == 2 ? "All-Rounder" :
                                                                item?.playerRole == 0 ? "Batsman" :
                                                                    item?.playerRole == 1 ? "Bowler" : ""

                                                    }
                                                </td>
                                                <td>{item?.playerFinalPoints}</td>
                                                <td>
                                                    {/* <img
                                                                src={playerSelections[item.id] ? crossIcon : plusIcon}
                                                                height={25}
                                                                width={25}
                                                                onClick={() => {
                                                                    if (totalBudget < 5) {
                                                                        toast.error("Budget Finished!")
                                                                    }

                                                                    else {
                                                                        toggleActiveTabOnePlayerSelection(item.id, item, activeTab)
                                                                    }
                                                                }
                                                                }
                                                            /> */}



                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                {message &&<tr>
                                    <td colSpan={4} className='text-center'>{message}</td>
                                </tr>}

                            </tbody>
                    }
                </table>

            </div>
        </div>
    );
}

export default ViewTeam;
