import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../redux/authSlice';
import { CustomAlerts, NotificationTypes } from '../../components/CustomAlerts/CustomAlerts';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogin = async () => {
    let body = { phoneNumber, password }

    dispatch(loginUser(body))
      .then((res) => {
        if (!res?.payload?.isSuccess) {
          CustomAlerts(res?.payload?.message, NotificationTypes.ERROR)
        }
        else {
          CustomAlerts("Successfully Login", NotificationTypes.SUCCESS)
          setTimeout(() => {
            navigate("/home")
          }, 1000);
        }
      })
      .catch((e) => {
        alert("login Rejected")
      })
  }


 




  return (
    <div className='w-100 d-flex justify-content-center align-items-center' id='login-page'>
      <div className='form-login d-flex justify-content-center align-items-center flex-column'>

        <div className='input-fields'>
          <h1 className='text-white text-center fw-bold'>Login</h1>
        </div>
        <div className='input-fields'>
          <input type="text"
            className='login-input'
            placeholder='Enter Your Phone Number'
            value={phoneNumber}
            onChange={(e) => {
              const input = e.target.value;
              const filteredInput = input.replace(/[^0-9+]/g, '');
              const maxLength = filteredInput.startsWith('+') ? 13 : 11;
              if (filteredInput.length <= maxLength) {
                setPhoneNumber(filteredInput);
              }
            }}
          />
        </div>
        <div className='input-fields'>
          <input type="password" className='login-input' placeholder='Enter Your Password' value={password} onChange={(e) => { setPassword(e.target.value) }} />

        </div>
       

        <div className='input-fields'>
          <button className='btn btn-outline-primary' onClick={handleLogin}>Login Now</button>
          
        </div>
        
        
        <div className='input-fields'>
          <h6 className='text-white'> Don't have an account ? <span className='fw-bold text-warning cursor-pointer' onClick={() => { navigate("/signup") }}>Sign Up</span></h6>
        </div>
        <div className='input-fields-forget mt-2' style={{width:"50%",display:"flex",justifyContent: 'center',alignItems:"flex-end"}}>
           <span className='fw-bold text-warning cursor-pointer' style={{textDecoration: "underline"}} onClick={() => { navigate("/forgot-password") }}>Forget Password</span>
        </div>
      </div>
    </div>
  )
}

export default Login
