import React, { useEffect, useState } from 'react';
import '../../styling/profile.css';
import crossIcon from "../../assets/images/cross-icon-red.png"
import DepositModal from '../../components/DepositModal/DepositModal';
import WithdrawMoneyModal from '../../components/WithdrawMoneyModal/WithdrawMoneyModal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/authSlice';
import { CustomAlerts, NotificationTypes } from '../../components/CustomAlerts/CustomAlerts';
import https from '../../utils/axiosApi';

const Profile = () => {
    // State for input fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mailingAddress, setMailingAddress] = useState('');
    const [bankName, setBankName] = useState('');
    const [ibanNumber, setIbanNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [modalAnimation, setModalAnimation] = useState('');
    const [modalAnimation1, setModalAnimation1] = useState('');
    const [activeTab, setActiveTab] = useState('bankTransfer');
    const [amount, setAmount] = useState("")
    const [imageURL, setImageURL] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [imageBase64, setImageBase64] = useState('');

    // setting data 
    useEffect(() => {
        https.get("/UserApi/GetUserById").then(async (res) => {
            let userData = await res?.data?.data;
            setFirstName(userData?.name?.split(' ')[0])
            setLastName(userData?.name?.split(' ')[1])
            setEmail(userData?.email)
            setMailingAddress(userData?.address)

            setImageURL(userData?.userProfileImage)

            setBankName(userData?.bankName)
            setIbanNumber(userData?.accountNo)
            setAccountNumber()
        })


    }, [])

    // Button click handlers

    const handleLogout = () => {
        dispatch(logout())
        CustomAlerts("Successfully Logout!", NotificationTypes.SUCCESS)
        setTimeout(() => {
            navigate("/login")
        }, 1500);

    };

    const handleUpdateProfile = () => {

        if (!firstName && !lastName && !email) {
            CustomAlerts("Please enter first name", NotificationTypes.ERROR)
        }
        else {
            let body = {
                name: firstName + " " + lastName,
                email: email,
                address: mailingAddress,
                bankName: bankName,
                accountNo: ibanNumber,
                userProfileImage: imageBase64
            }
            https.post("/UserApi/UpdateUser", body).then((res) => {
                CustomAlerts("profile updated successfully", NotificationTypes.SUCCESS)
                setTimeout(() => {
                    // window.location.reload()
                }, 1500);

            }).catch((err) => {
                console.log({ err })
            })
        }

    };




    const handleOpenModal = () => {
        setShowModal(true);
        setModalAnimation('modal-custom-enter-active');
    };


    const handleCloseModal = () => {
        setModalAnimation('modal-custom-exit-active');
        setTimeout(() => {
            setShowModal(false);
        }, 300); // Should match the duration of the CSS transition
    };



    const handleDepositAmount = () => {
        handleOpenModal();
    };


    const getAnimationStyle = (tabName) => {
        return activeTab === tabName ? 'fadeIn 0.5s' : 'fadeOut 0.5s';
    };


    const handleCloseModal1 = () => {
        setModalAnimation('modal-custom-exit-active');
        setTimeout(() => {
            setShowModal1(false);
        }, 300); // Should match the duration of the CSS transition
    };


    const handleOpenModal1 = () => {
        setShowModal1(true);
        setModalAnimation('modal-custom-enter-active');
    };
    const handleWithdrawalAmount = () => {
        handleOpenModal1();
    };



    const handleIconClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.match('image.*')) {
            const reader = new FileReader();
            
            reader.onload = (e) => {
                setImageURL(e.target.result);
            };
            reader.onloadend = () => {
                let base64String = reader.result;
                // Remove the data URL prefix if present
                const base64Data = base64String.split(',')[1];
                setImageBase64(base64Data);
            };
            
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className='w-100 d-flex justify-content-center align-items-center ' style={{ position: "relative" }} id='profile-page-style'>
            {showModal && (
                <DepositModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    modalAnimation={modalAnimation}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleCloseModal={handleCloseModal}
                    handleDepositAmount={handleDepositAmount}
                    getAnimationStyle={getAnimationStyle}
                    amount={amount}
                    setAmount={setAmount}

                />
            )}


            {showModal1 && (
                <WithdrawMoneyModal
                    showModal={showModal1}
                    setShowModal={setShowModal1}
                    modalAnimation={modalAnimation}
                    // activeTab={activeTab}
                    // setActiveTab={setActiveTab}
                    handleCloseModal={handleCloseModal1}
                    handleDepositAmount={handleWithdrawalAmount}
                    getAnimationStyle={getAnimationStyle}
                    amount={amount}
                    setAmount={setAmount}

                />
            )}

            <div className='form-login my-5 full-height'>
                <div className='header-profile' style={{
                    background: "white",
                    backgroundImage: `url("${imageURL || 'https://images.pexels.com/photos/1898555/pexels-photo-1898555.jpeg'}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}>

                    <div className='profile-icon-box' onClick={handleIconClick} style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-camera fa-2xl" style={{ color: "#8763ca" }}></i>
                    </div>
                    <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
                </div>
                <div className='main-profile-area' id='profile-area'>
                    <div className='input-fields w-75' style={{ float: "right", justifyContent: "flex-end" }}>
                        <h3 className='fw-bold text-white text-profile'>My Profile</h3>
                        <button className='btn btn-outline-primary mx-3' style={{ borderRadius: "10px", width: 120, marginRight: 0 }} onClick={handleLogout}>Logout</button>
                    </div>
                    <div className='input-fields'>
                        <input type="text" className='login-input login-half-input' placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} style={{ color: "#7a548d" }} />
                        <input type="text" className='login-input login-half-input' placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} style={{ color: "#7a548d" }} />
                    </div>
                    <div className='input-fields'>
                        <input type="text" className='login-input login-full-input' placeholder='Email ID' value={email} onChange={(e) => setEmail(e.target.value)} style={{ color: "#7a548d" }} />
                    </div>
                    <div className='input-fields'>
                        <input type="text" className='login-input login-full-input' placeholder='Mailing Address' value={mailingAddress} onChange={(e) => setMailingAddress(e.target.value)} style={{ color: "#7a548d" }} />
                    </div>
                    <div className='input-fields heading-bank-details mt-3 mb-0'>
                        <h3 className='fw-bold text-white'>Withdrawal Details</h3>
                    </div>
                    <div className='input-fields mt-0'>
                        <input type="text" className='login-input login-full-input' placeholder='Bank Name' value={bankName} onChange={(e) => setBankName(e.target.value)} style={{ color: "#7a548d" }} />
                    </div>
                    <div className='input-fields'>
                        <input type="text" className='login-input login-full-input' placeholder='IBAN Number' value={ibanNumber} onChange={(e) => setIbanNumber(e.target.value)} style={{ color: "#7a548d" }} />
                    </div>
                    <div className='input-fields'>
                        <input type="text" className='login-input login-full-input' placeholder='Account Number' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} style={{ color: "#7a548d" }} />
                    </div>

                    <div className='input-fields profile-btn-area'>
                        <button className='btn btn-outline-primary' onClick={handleUpdateProfile}>Update Profile</button>
                        <button className='btn btn-outline-primary' onClick={handleDepositAmount}>Deposit Amount</button>
                        <button className='btn btn-outline-primary' onClick={handleWithdrawalAmount}>Withdrawal Amount</button>
                    </div>
                </div>
                <div style={{
                    height: 30,
                    width: 100,
                    position: "absolute",
                    bottom: "-40px",
                    right: "-50px",
                    fontSize: 15,
                    cursor: "pointer"
                }} onClick={() => { navigate("/home") }}
                ><i className="fa-solid fa-arrow-left-long fa-2xl" style={{ color: "#ffffff" }}></i></div>
            </div>
        </div>
    )
}

export default Profile
