import React from 'react'

const LoaderLarge = ({  }) => {
  return (
    <div className={`spinner-border text-white spinner-border-lg`} role="status">
    </div>
  )
}

export default LoaderLarge
