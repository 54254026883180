import React, { useState } from 'react';
import CounterDown from '../CounterDown/CounterDown';
import { useNavigate } from 'react-router-dom';

const TournmentsCards = ({ data,matchType }) => {
    const navigate = useNavigate()

    return (
        <div className='tournament-box' style={{ cursor: "pointer" }} onClick={() => {
            if (window.location.pathname !== "/match-updates") {
                navigate("/match-updates", { state: { tournamentData: data,matchType,matchType } });
                // localStorage.setItem("tournamentData",JSON.parse(data))
            }
        }}>
            <div className='top-area'>
                <span className='text'>{data?.name}</span>
            </div>
            <div className='center-area'>
                <div className='center-area-box'>
                    <div className='center-area-img'>
                        <img
                            src={data?.team1Logo}
                            alt="team1Logo"
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                        />
                    </div>
                    <div>
                        <h6>{data?.team1Name}</h6>
                    </div>
                </div>
                <div className='center-area-box'>
                    <div className='center-area-img'>
                        <img
                            src={data?.team2Logo}
                            alt="team1Logo"
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                        />
                    </div>
                    <div>
                        <h6>{data?.team2Name}</h6>
                    </div>
                </div>
            </div>
            <div className='bottom-area'>
                <span className='text'>{<CounterDown targetDateTime={data?.startTime} />}</span>
            </div>
        </div>
    )
}

export default TournmentsCards;
