import React, { useEffect, useState, useRef } from 'react';
import https from '../../utils/axiosApi';
import moment from 'moment';
import LoaderLarge from '../../components/Common/LoaderLarge';

const TransactionHistory = () => {
    const [transactionData, setTransactionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const loaderRef = useRef(null);

    const fetchTransactionHistoryData = async () => {
        setLoading(true);
        try {
            const response = await https.get(`/api/UserTransactionHistoryApi/GetUserTransactionHistory?pageNumber=${pageNumber}&pageSize=20`);
            const resData = response?.data?.data;
            if (resData?.length === 0) {
                setHasMore(false);
            }
            // Introduce a delay of 1 second before updating the state
            setTimeout(() => {
                setTransactionData(prevData => [...prevData, ...resData]);
                setPageNumber(prevPage => prevPage + 1); // Update pageNumber here
                setLoading(false);
            }, 1000); // 1000 milliseconds = 1 second
        } catch (error) {
            console.error('Error fetching transaction history:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchTransactionHistoryData();
    }, [pageNumber]); // Call fetchTransactionHistoryData whenever pageNumber changes

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target?.isIntersecting && hasMore) {
            setPageNumber(prevPage => prevPage + 1); // Update pageNumber when intersection occurs
        }
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, options);
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [hasMore]);

    const getDateFormat = (date) => {
        if (window.innerWidth <= 768) {
            return moment(date).format('DD-MM-YYYY, h:mmA');
        } else {
            return moment(date).format('MMM DD, YYYY, h:mm A');
        }
    }

    return (
        <div className='my-5 main-info-container w-100 d-flex justify-content-center align-items-center flex-column'>
            <div id='match-updates-container'>
                <h3>Transaction History</h3>
            </div>

            <div className='main-container-matches my-3' style={{ width: "80%" }}>
                <table className="table table-bordered">
                    <tbody id='table-body'>
                        {
                            transactionData?.length > 0 && <tr>
                                <th>Date</th>
                                <th>Details</th>
                                <th>Match</th>
                                <th>Team</th>
                                <th>Contest</th>
                                <th>Amount</th>
                            </tr>
                        }

                        {transactionData.map((item, index) => (
                            <tr key={index} className='table-transaction'>
                                <td scope="row" width={"20%"}>{getDateFormat(item?.date)}</td>
                                <td>{item?.details}</td>
                                <td>{item?.matchName}</td>
                                <td>{item?.teamName}</td>
                                <td>{item?.contestName}</td>
                                <td className={item?.trasactionType === "Debit" ? 'text-danger fw-bold' : "text-success fw-bold"}>{item?.amount}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                {loading && <div className='d-flex justify-content-center align-items-center'><LoaderLarge size="large" /></div>}
                {
                    transactionData?.length === 0 ? <div className='fw-bold text-white d-flex justify-content-center align-items-center my-5' >No transactions found</div> :

                        !hasMore && <div className='fw-bold text-white d-flex justify-content-center align-items-center my-5' >No more transactions</div>}
                <div ref={loaderRef}></div>
            </div>
        </div>
    );
}

export default TransactionHistory;
