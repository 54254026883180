import React, { useState } from 'react';
import './points.css';

const PointsSystem = () => {
  const [activeTab, setActiveTab] = useState('T20');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const dropdownOptions = {
    T20: {
      Batting: [
        { label: '1 Run', points: '+1 Point' },
        { label: 'Each 50 Runs', points: '+35 Points' },
        { label: 'Strike Rate < 50', points: '-20 Points' },
        { label: 'Strike Rate > 50 <= 90', points: '-10 Points' },
        { label: 'Strike Rate > 120 <= 150', points: '+10 Points' },
        { label: 'Strike Rate > 150 <= 200', points: '+20 Points' },
        { label: 'Strike Rate > 200 <= 250', points: '+25 Points' },
        { label: 'Strike Rate > 250', points: '+30 Points' },
        { label: 'Each 4', points: '+1 Points' },
        { label: 'Each 6', points: '+2 Points' },
      ],
      Bowling: [
        { label: 'Wicket', points: '+30 Points' },
        { label: 'Maiden Over', points: '+15 Points' },
        { label: '3 Wickets', points: '+30 Points' },
        { label: '5 Wickets', points: '+50 Points' },
        { label: 'Econoy < 3', points: '+30 Points' },
        { label: 'Econoy >= 3 < 4', points: '+25 Points' },
        { label: 'Econoy >= 4 < 6', points: '+10 Points' },
        { label: 'Econoy >= 8 < 10', points: '-5 Points' },
        { label: 'Econoy >= 10 < 12', points: '-15 Points' },
        { label: 'Econoy >= 12', points: '-25 Points' },
      ],
      Fielding: [
        { label: 'Catch', points: '+10 Points' },
        { label: 'Stumps', points: '+15 Points' },
        { label: 'Run Out', points: '+10 Points' },
      ],
    },
    ODI: {
      Batting: [
        { label: '1 Run', points: '+1 Point' },
        { label: 'Each 50 Runs', points: '+30 Points' },
        { label: 'Strike Rate < 40', points: '-20 Points' },
        { label: 'Strike Rate < 70', points: '-10 Points' },
        { label: 'Strike Rate > 100 <= 120', points: '+5 Points' },
        { label: 'Strike Rate > 120 <= 150', points: '+15 Points' },
        { label: 'Strike Rate > 150 <= 200', points: '+20 Points' },
        { label: 'Strike Rate > 200', points: '+25 Points' },
        { label: 'Each 4', points: '+1 Points' },
        { label: 'Each 6', points: '+3 Points' },
      ],
      Bowling: [
        { label: 'Wicket', points: '+30 Points' },
        { label: 'Maiden Over', points: '+5 Points' },
        { label: '3 Wickets', points: '+30 Points' },
        { label: '5 Wickets', points: '+70 Points' },
        { label: 'Econoy < 3', points: '+20 Points' },
        { label: 'Econoy >= 3 < 5', points: '+10 Points' },
        { label: 'Econoy >= 7 < 9', points: '-10 Points' },
        { label: 'Econoy >= 9 < 10', points: '-15 Points' },
        { label: 'Econoy >= 10', points: '-20 Points' },
      ],
      Fielding: [
        { label: 'Catch', points: '+10 Points' },
        { label: 'Stumps', points: '+15 Points' },
        { label: 'Run Out', points: '+10 Points' },
      ],
    },
    Test: {
      Batting: [
        { label: '1 Run', points: '+1 Point' },
        { label: 'Each 50 Runs', points: '+35 Points' },
   
        { label: 'Strike Rate < 70', points: '-' },
        { label: 'Strike Rate > 100 <= 120', points: '-' },
        { label: 'Strike Rate > 120 <= 150', points: '-' },
        { label: 'Strike Rate > 150 <= 200', points: '-' },
        { label: 'Strike Rate > 200', points: '-' },
        { label: 'Duck', points: '-15 Points' },
        { label: 'Each 4', points: '+2 Points' },
        { label: 'Each 6', points: '+5 Points' },
      ],
      Bowling: [
        { label: 'Wicket', points: '+35 Points' },
        { label: 'Maiden Over', points: '+2 Points' },
        { label: '3 Wickets', points: '+35 Points' },
        { label: '5 Wickets', points: '+70 Points' },
        { label: 'Econoy > 6', points: '-10 Points' },
      ],
      Fielding: [
        { label: 'Catch', points: '+10 Points' },
        { label: 'Stumps', points: '+15 Points' },
        { label: 'Run Out', points: '+10 Points' },
      ],
    },
  };

  const [dropDownStates, setDropDownStates] = useState({
    Batting: false,
    Bowling: false,
    Fielding: false,
  });

  const toggleDropDown = (dropDownName) => {
    setDropDownStates((prevState) => ({
      ...prevState,
      [dropDownName]: !prevState[dropDownName],
    }));
  };

  const renderDropDown = () => {
    return Object.keys(dropdownOptions[activeTab]).map((category) => {
      return (
        <div className='drop-down-points my-5' key={category}>
          <div className='top-bar' onClick={() => toggleDropDown(category)}>
            <h6 className='text-white fw-bold'>{category} Points</h6>
            <i className={`fa-solid ${dropDownStates[category] ? 'fa-chevron-up' : 'fa-chevron-down'} fa-lg text-white`}></i>
          </div>
          {dropDownStates[category] && renderOptions(category)}
        </div>
      );
    });
  };

  const renderOptions = (category) => {
    return dropdownOptions[activeTab][category].map((option, index) => (
      <div className='top-sub-bar' key={index}>
        <h6 className='text-white'>{option.label}</h6>
        <h6 className='text-white'>{option.points}</h6>
      </div>
    ));
  };

  return (
    <div className='container my-5 points-sys' id='points-eco'>
    <div className='points-notice'>
      <ul>
        <li>Economy points of bowlers will be half untill they complete 2 overs</li>
        <li>Strike Rate points of Batsmen will be half untill they face 10 balls</li>
      </ul>
    </div>
      <div className='tab-menu'>
        <button onClick={() => handleTabChange('T20')} className={activeTab === 'T20' ? 'active-btn' : ''}>
          T20 Points
        </button>
        <button onClick={() => handleTabChange('ODI')} className={activeTab === 'ODI' ? 'active-btn' : ''}>
          ODI Points
        </button>
        <button onClick={() => handleTabChange('Test')} className={activeTab === 'Test' ? 'active-btn' : ''}>
          Test
        </button>
        {/* Add more buttons for other tabs here */}
      </div>
      <div className='tab-content'>
        {renderDropDown()}
      </div>
    </div>
  );
};

export default PointsSystem;
